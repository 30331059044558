import React from "react";
import ServiceBanner from "./BannerSection/ServiceBanner";
import Process from "./ProcessSection/Process";
import { useParams } from "react-router";

function ServiceDetails() {
  let { id } = useParams();
  return (
    <div>
      <ServiceBanner />
      <Process id={id} />
      <div></div>
    </div>
  );
}

export default ServiceDetails;
