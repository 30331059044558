import React, { useEffect, useState } from "react";
import axios from "axios";
import DotNet from "./../../../Assets/Images/DotNet.png";
import parse from 'html-react-parser';
import './ProjectTech.css'

function ProjectTechnology({ id }) {
  const [techData, setData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://testwebapiqttzaz.azurewebsites.net/api/Technology/getAllProjectTechnology?id=${id}`
      )
      .then((response) => {
        setData(response.data);
      });
  }, [id]);
  return (
    <div>
      <div className=" themeColour sectionMergin d-flex justify-content-center ">
        <div className="container-fluid sectionContainer ">
          <div className="row p-4">
            <div className="col-md-12 ">
              <p className=" fs-2 fw-semibold">Technology</p>
            </div>
          </div>
          <div className="row d-flex justify-content-center  d-flex align-items-center ">
            {
              parse(techData[0].logo)
            }
            {/* {techData.map((data) => {
              return (
                <div
                  className="col-lg-2 col-4 d-flex justify-content-center rounded-4 pt-3 mb-4 me-4 "
                  style={{ backgroundColor: "rgb(142 223 223)" }}
                  key={data.id}
                >
                  <div className="technologyBox d-flex justify-content-center">
                    <div className="technologyImage">
                      <img
                        src={require(`./../../../Assets/Images/${data.logo}`)}
                        alt={data.name}
                        className="technologyImage"
                      />
                    </div>
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectTechnology;
