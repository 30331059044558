import React, { useState } from "react";
import { GoPlusCircle } from "react-icons/go";
import { CiCircleMinus } from "react-icons/ci";
import "./style.css";
const DataAnalyticsCart = ({ data }) => {
  const [expand, setExpand] = useState(false);
  return (
    <div
      className={`animationDuration analyticsCart ${
        expand ? "cartexpand" : "cartcolaps"
      }`}
    >
      <h1>{data.name}</h1>
      <ul className={`animationDuration ${expand ? "expand" : "colaps"}`}>
        {data.points?.map((item, idx) => {
          return <li key={idx}>{item}</li>;
        })}
      </ul>
      <div className="buttonDiv">
        <button onClick={() => setExpand((prev) => !prev)}>
          {expand ? <CiCircleMinus /> : <GoPlusCircle />}
        </button>
      </div>
    </div>
  );
};

export default DataAnalyticsCart;
