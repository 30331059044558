import React, { useEffect } from "react";
import Contact from "./Contact";
import Aos from "aos";
import "aos/dist/aos.css";

const Location = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <div className="container d-flex justify-content-center sectionMergin">
      <div className="sectionContainer">
        <div
          className="row"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <div  className="col-md-12 ">
            <h2 className=" text-center fs-1 mb-5 "  style={{color: "black", fontWeight: "500", fontFamily: "Montserrat", fontSize: "36px"}}>
              Grow Your Business With Our Expertise
            </h2>
          </div>
        </div>

        <div className="row">
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <Contact />
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
              
            <iframe
              src="https://www.google.com/maps/embed/v1/place?q=5+Brayford+Square,+London+E1+0SG,+UK&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="location w-100"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
