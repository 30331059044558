import cSharp from "./Asset/c#.svg";
import powerShall from "./Asset/powerShall.svg";
import fSharp from "./Asset/f#.svg";
import VB from "./Asset/vb.svg";
import sql from "./Asset/sql.svg";
import tsql from "./Asset/ts.svg";
import js from "./Asset/js.svg";
import css from "./Asset/css.svg";
import html from "./Asset/html.svg";
import xml from "./Asset/xml.svg";
import ts from "./Asset/ts.svg";
import py from "./Asset/py.svg";
import vs from "./Asset/vs.svg";
import vsc from "./Asset/vsc.svg";
import rd from "./Asset/rd.svg";
import netForm from "./Asset/net.svg";
import netCore from "./Asset/netCore.svg";
import addNet from "./Asset/addNet.svg";
import entity from "./Asset/entity-framework.svg";
import silverLIght from "./Asset/silverLIght.svg";
import wcf from "./Asset/wcf.svg";
import aspMvc from "./Asset/aspMvc.svg";
import aspWebform from "./Asset/aspWebform.svg";
import uwp from "./Asset/Uwp.svg";
import wpf from "./Asset/wpf.svg";
import windowsform from "./Asset/windows-forms.svg";
import xamarin from "./Asset/xamarin.svg";
import mediatr from "./Asset/mediatr.svg";
import signalr from "./Asset/signalr.svg";
import polly from "./Asset/polly.svg";
import fluent from "./Asset/fluent-validation.svg";
import blazor from "./Asset/blazor.svg";
import mvvtalkit from "./Asset/mvvm-toolkit.svg";
import mvvtalklight from "./Asset/mvvm-light-toolkit.svg";
import devextreem from "./Asset/devextreme.svg";
import aws from "./Asset/aws.svg";
import azure from "./Asset/azure-full.svg";
import diocian from "./Asset/digital-ocean.svg";
import googleClowd from "./Asset/google-cloud-logo.svg";
import rackspace from "./Asset/rackspace-horizontal.svg";
import mstool from "./Asset/mstest.svg";
import spaceflower from "./Asset/specflow.svg";
import moq from "./Asset/moq.svg";
import nuint from "./Asset/nunit-xunit.svg";
import postman from "./Asset/postman.svg";
import se from "./Asset/selenium.svg";
import azureDev from "./Asset/azure-devops-logo-vertical.svg";
import azureappinlight from "./Asset/application-insights-full.svg";
import tc from "./Asset/teamcity.svg";
import jenkins from "./Asset/gitlab.svg";
import ci from "./Asset/gitlab-ci-cd-logo.svg";
import docker from "./Asset/docker-horizontal.svg";
import kubernates from "./Asset/kubernetes-logo.svg";
import grafana from "./Asset/grafana-logo.svg";
import promethus from "./Asset/prometheus-logo.svg";
import dataDob from "./Asset/datadog-logo.svg";
import newrelice from "./Asset/datadog-logo.svg";
import awsXray from "./Asset/aws-x-ray.svg";
import awsclowdWatch from "./Asset/aws-cloudwatch.svg";
import react from "./Asset/react.png";
import nodejs from "./Asset/nodejs.png";
import dotnet from "./Asset/dotnet.png";
import java from "./Asset/java.png";
import kotlin from "./Asset/kotlin.png";
import swipt from "./Asset/swipt.png";
import illustrator from "./Asset/illustrator.png";
import photoshop from "./Asset/photoshop.png";
import sketch from "./Asset/sketch.png";
import figma from "./Asset/figma.png";
import firebase from "./Asset/firebase.png";
import sqlte from "./Asset/sqlite.png"
import msSql from "./Asset/mssql.png"

const web = [dotnet, nodejs, react, blazor, py, html];

const mobile = [java, kotlin, swipt, react];

const ui = [photoshop, illustrator, sketch, figma];

const clowd = [azure, firebase];
const database = [sql,sqlte,msSql];
const Ides=[vs,vsc,rd]

const getlanguageAndIde=()=>{
    return {web,mobile,ui,clowd,database,Ides}
}

export default getlanguageAndIde

//  const ProgrammingLanguage =[cSharp,powerShall,fSharp,VB,sql,tsql,js,css,html,xml,ts,py]



// const framework=[netForm,netCore,addNet,entity,silverLIght,wcf,aspMvc,aspWebform,uwp,wpf,windowsform,xamarin]

// const componentAndLibrarys=[mediatr,signalr,polly,fluent,blazor,mvvtalkit,mvvtalklight,devextreem]

// const clouds =[aws,azure,googleClowd,diocian,rackspace]

// const qatools=[mstool,spaceflower,moq,nuint,postman,se]

// const devops=[azureDev,azureappinlight,tc,jenkins]
