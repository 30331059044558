import React, { useEffect, useState } from "react";
import axios from "axios";
import "./OurOfferedSolutions.css";
import goImg from "./../../../Assets/Images/Go5050.png";
import gotasteImg from "./../../../Assets/Images/Gotaste Logo-13.png";
import pantomimeImg from "./../../../Assets/Images/sproutLogo.png";
import paragonImg from "./../../../Assets/Images/image001.png";
import smImg from "./../../../Assets/Images/SMKW_logo3.png";
import rhtiImg from "./../../../Assets/Images/team2.png";
import rediImg from "./../../../Assets/Images/team3.png";
import silverspooniImg from "./../../../Assets/Images/team4.png";
import nestiImg from "./../../../Assets/Images/team6.png";
import quentediImg from "./../../../Assets/Images/team7.png";
import infiniiImg from "./../../../Assets/Images/logo (1).png";

import Aos from "aos";
import "aos/dist/aos.css";

const OurOfferedSolutions = () => {
 
  
  return (
    
    <div className="container offered">
      <div className="container">
        <div className="offered-fluid text-center">
          <h2 className="text-center my-5">Our Offered Software Development Solutions</h2>                    
          <div className="row d-flex justify-content-center">
            
            <p id="offeredSolutionParagraph">
            Discover our comprehensive Software Development Solutions designed to elevate your business. From custom software development to application maintenance and support, our team of experts is dedicated to delivering innovative software solutions that meet your unique business needs. With a focus on quality, efficiency, and scalability, we are committed to helping you achieve your goals and stay ahead of the competition. Partner with us to drive efficiency, innovation, and growth through cutting-edge software development.
            </p>

            <h4>Following are the key solutions that we offer:</h4>
           
            <div className="col-6 col-md-3  offered-col">
              <a
                className=""
                          
              >
                Enterprise Application
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                ERP
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
              Financial Management  
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                Supply Change Management
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                Assets Management
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                Fleet Management
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                HR Software
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                E Learning Software
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                E-Commerce
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                Mobile Apps
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                Document Managment
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                Content Managment
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                CRM
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                Web Portals
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                Marketing & Advertising
              </a>
            </div>
            <div className="col-6 col-md-3 offered-col">
              <a
                className=""
                          
              >
                Data Analytics
              </a>
            </div>
           
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurOfferedSolutions;
