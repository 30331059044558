import React from 'react';
import "./style.css"
import { getAnalyticsArea, getIndustryAnalytics } from './DataAnalyticsDemoData';
import DataAnalyticsCart from './Cart/DataAnalyticsCart';
import IndustriesSection from '../../../IndustriesWorkWith/Industries';
const DataAnalyticsService = () => {
    return (
        <>
        <div className='centerContainer container'>
            <h1 className='tittleText analycisTittle'>Data Analytics services we offer</h1>
            <h1 className='Sub-tittle'>By industry</h1>
            <div style={{padding:"5px"}} className='AnalyticsGrid'>
                {
                    getIndustryAnalytics()?.map((item,idx)=><DataAnalyticsCart key={idx} data={item}/>)
                }
            </div>
            <h1 className='Sub-tittle'>By analytics area</h1>
            <div style={{padding:"5px"}} className='AnalyticsGrid'>
                {
                    getAnalyticsArea()?.map((item,idx)=><DataAnalyticsCart key={idx} data={item}/>)
                }
            </div>
        </div>
        <IndustriesSection/>
        </>
    );
};

export default DataAnalyticsService;