import IndustriesSection from "../../../IndustriesWorkWith/Industries";
import "./SoftwareConsultingServices.css"
function SoftwareConsultingServices() {


  return (
    <div className="container">
      <div className="softwate-consulting">
        <div className="toppart text-center">
          <h2 className="game-feature-heading1 mb-5">Our Consulting  Services</h2>

        </div>
        <div className="box">
          <div className="row">
            <div className="col-12">

              <ul className="row d-flex justify-content-between p-0">

                <li className=" border-right full-width-image-softwate-consulting mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/programming.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Web Consulting</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-softwate-consulting mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/app-development.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Mobile Consulting</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-softwate-consulting mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/saas (1).png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">SaaS Consulting</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-softwate-consulting mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/cloud-server.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Cloud Consulting</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-softwate-consulting mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/analysis.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Data Analytics Consulting</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-softwate-consulting mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/supply-chain.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Supply Chain Management Consulting</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-softwate-consulting mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/inventory.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Inventory Management Consulting</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-softwate-consulting mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/ecommerce.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Ecommerce Consulting</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-softwate-consulting mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/task-management.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Project Management Consulting</h5>

                  </a>

                </li>
              </ul>


            </div>
          </div>
        </div>
      </div>

      <IndustriesSection/>
      
    </div>
  )


}

export default SoftwareConsultingServices;