import React from "react";
import process from "./../../../Assets/Images/ProcessOne.png";

function ProcessComponentReverse({ processname, description, processimage }) {
  return (
    <div>
      <div>
        <div>
          <div>
            <div>
              <div className="row mb-5">
                <div className="col-md-5 col-12 d-flex justify-content-center ">
                  <div className=" processImageBox d-flex align-items-center">
                    <img
                      src={processimage}
                      alt="process"
                      className="processImage"
                    />
                  </div>
                </div>
                <div className="col-md-7 col-12 themeColour rounded-2 d-flex align-items-center ">
                  <div className=" ">
                    <p className=" text-start fs-3  fw-semibold">
                      {processname}{" "}
                    </p>
                    <p className=" text-start fs-5  fw-light">{description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessComponentReverse;
