import IndustriesSection from "../../../IndustriesWorkWith/Industries";
import OurOfferedSolutions from "../../../HomePage/OurOfferedSolutions/OurOfferedSolutions";
function BusinessAnalysis (){

    return (
        <div className="container">            
            <IndustriesSection/>   
            <OurOfferedSolutions />      

        </div>
    )

}

export default BusinessAnalysis;