import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import NavBar from "../../HomePage/NavBar/NavBar";
import BannerSection from "../../HomePage/BannerSection/BannerSection";
import Review from "../../HomePage/Review/Review";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./ServiceDetailsView.css";
import CustomSoftware from "./CoustomeSoftware/CustomeSoftware";
import SoftwareConsultingServices from "./SoftwareConsultingServices/SoftwareConsultingServices";
import WebDevelopment from "./WebDevelopment/WebDevelopment";
import MobileAppDevelopment from "./MobileAppDevelopment/MobileAppDevelopment";
import Footer from "../../HomePage/Footer/Footer";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

import img1 from "./../../../Assets/Images/S1/WhatsApp Image 2023-10-14 at 18.03.28_6856b413.jpg";
import img2 from "./../../../Assets/Images/S1/p2.jpg";
import img3 from "./../../../Assets/Images/S1/s2.png";
import img4 from "./../../../Assets/Images/S1/s3.png";
import Test from "../../../test";

import { getDevelopersDetails } from "./Demodata";
import Technology from "../../HomePage/Technology/Technology";
import DataAnalyticsService from "./DataAnalytics/DataAnalyticsService";
import IndustriesSection from "../../IndustriesWorkWith/Industries";
import DevelopersForHigher from "./DevelopersForHigher/DevelopersForHigher";
import BusinessAnalysis from "./BusinessAnalysis/BusinessAnalysis";

function ServiceDetailsView() {
  const [serviceData, setData] = useState();
  let { slug } = useParams();
  const [loading, setIsloading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(slug);
    getAllData();
  }, [slug]);

  const getAllData = () => {
    setIsloading(true);
    axios
      .get(
        "https://testwebapiqttzaz.azurewebsites.net/api/Service/getAllServicedetailsbyslug?slug=" +
          slug
      )
      .then((response) => {
        setData(response.data);
        setIsloading(false);
      });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <img src={require("./../../../Assets/Images/Loader.gif")} />
      </div>
    );
  } else {



const validateContent=()=>{
if(slug==="developers-for-hire-services") return <DevelopersForHigher/>
if(slug==="custom-software-development-services") return <CustomSoftware />
if(slug==="web-development-services") return  <WebDevelopment />
if(slug==="software-consulting-services") return <SoftwareConsultingServices />
if(slug==="data-analytics-services") return <DataAnalyticsService/>
if(slug==="mobile-app-development-services") return <MobileAppDevelopment />
if(slug === "business-analysis-and-intelligence") return <BusinessAnalysis/>
return parse(serviceData.serviceDetail.contents)
}

console.log(slug)
const getBannerDescription=()=>{
  if(slug === "developers-for-hire-services") return "The developers we are experienced in developing and maintaining web applications, mobile apps, databases, cloud solutions, SaaS, and more. With a focus on delivering exceptional results, our professionals are dedicated to helping your business achieve its software development goals."
  if(slug === "software-consulting-services") return "Our Software Consulting service provides expert advice and guidance on software development, implementation, and optimization to help businesses achieve their goals efficiently. Our team of experienced consultants works closely with clients to identify their specific needs and provide customized recommendations for achieving their goals. Whether you are looking to streamline your current software processes, implement new technology, or optimize existing systems, our consultants have the knowledge and expertise to help you succeed."
  if(slug === "mobile-app-development-services") return "Transform your business with our custom mobile app development services for startups, scaleups, and enterprises, delivering tailored iOS and Android applications that drive results.We craft innovative, high-performance apps designed to fit your specific goals and needs. Whether you need a native app or a versatile cross-platform solution, our team delivers seamless functionality, intuitive UI/UX design, and a user experience that keeps your audience engaged."
  if(slug === "custom-software-development-services") return "Our Custom Software Development service provides end-to-end software development solutions tailored to your business needs. We specialise in software consulting, web and mobile app development, desktop applications, cloud-based solutions, SaaS and database development, API integration, and software upgrades. Whether you're building from scratch or enhancing existing systems, our team ensures high-quality, scalable software to drive your success."
  if(slug === "web-development-services") return "Transform your online presence with our Web Development services. We specialize in creating custom, responsive websites tailored to your business needs. From intuitive design to robust functionality, our team ensures a seamless user experience and effective performance. Whether you're launching a new site or revamping an existing one, we deliver innovative solutions that drive engagement and achieve your digital goals."
  if(slug === "data-analytics-services") return "Our Data Analytics Services help businesses make informed decisions by analyzing large volumes of data to uncover patterns, trends, and insights. Our team of experienced data scientists and analysts use cutting-edge tools and techniques to extract valuable information from complex datasets, providing actionable recommendations for improving performance and driving growth. Whether you need help with data visualization, predictive modeling, or data mining, our Data Analytics Services can provide the expertise and support you need to leverage your data effectively."
}

const getBannerDescription2=()=>{
  if(slug === "developers-for-hire-services") return "Looking to expand your team with skilled developers? Contact us today to hire dedicated software experts who can help bring your projects to life."
  if(slug === "software-consulting-services") return "Contact us today to schedule a consultation and see how our Software Consulting Services can benefit your business."
  if(slug === "mobile-app-development-services") return "Want to turn your app idea into a million-dollar product? Get in touch with us today for expert mobile app development solutions!"
  if(slug === "custom-software-development-services") return " Get in touch with us to discover how our custom software development services can be tailored to meet your unique business needs!"
  if(slug === "web-development-services") return "Reach out to us today to discuss how our Web Development services can help your business thrive in the digital landscape. "
  if(slug === "data-analytics-services") return "Contact us today to learn more about how we can help your business thrive in the age of big data. "
}


    return (
      <div>
        {serviceData !== undefined ? (
          <>
            <HelmetProvider>
              <Helmet>
                <meta charSet="utf-8" />
                <title>{serviceData.serviceDetail.title}</title>
                <meta
                  name="description"
                  content={serviceData.serviceDetail.description}
                />
              </Helmet>
            </HelmetProvider>
            <BannerSection
              TwoButton={slug === "developers-for-hire-services"}
              description={getBannerDescription()}
              description2={getBannerDescription2()}
              fromText={serviceData.serviceDetail.title}
            />

            <div className="serviceDetailsPage">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb justify-content-end mb-3 me-3">
                  <li className="breadcrumb-item">
                    <Link to={"/"} className="breadCrumbText">
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/our-services"} className="breadCrumbText">
                      Our Services
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {serviceData.serviceDetail.title}
                  </li>
                </ol>
              </nav>

              {validateContent()}

              
              {serviceData.faqs !== undefined && serviceData.faqs !== null ? (
                <div className="row_modified mx-5">
                  <h2 class="tittleText">Frequently Asked Questions (FAQs)</h2>
                  <div
                    className="accordion d-grid gap-4 sectionContainer"
                    style={{ margin: "Auto" }}
                  >
                    
                    {serviceData.faqs.map((data, i) => {
                      return (
                        <>
                          <div
                            className="accordion-item rounded-4"
                            data-aos="fade-up"
                            data-aos-anchor-placement="bottom-bottom"
                            key={i}
                          >
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button gradientColor collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#" + i}
                                // aria-expanded="true"
                                aria-controls={i}
                                style={{ color: "white" }}
                              >
                                {data.question}
                              </button>
                            </h2>
                            <div
                              id={i}
                              className="accordion-collapse collapse "
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                              style={{
                                backgroundColor: "#117488",
                                color: "white",
                                fontSize: "18px",
                              }}
                            >
                              <div className="accordion-body">
                                {data.answer}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          "Loading"
        )}
      </div>
    );
  }
}

export default ServiceDetailsView;
