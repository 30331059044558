import React from "react";
import Technology from "../../../HomePage/Technology/Technology";
import { getDevelopersDetails } from "../Demodata";
import LanguageAndIde from "./subcomponent/LanguageAndIde/LanguageAndIde";
import OurProjects from "./subcomponent/OuerProjects/OurProjects";
import HowWeHire from "./subcomponent/HowWeHire/HowWeHire";
import EnsuringQualityOfCode from "./subcomponent/EnsuringQualityOfCode/EnsuringQualityOfCode";
import CorporationModel from "./subcomponent/CorporationModel/CorporationModel";
import SuccessFullDelivery from "./subcomponent/SuccessfullDelivery/SuccessFullDelivery";
import OurArchitechcher from "./subcomponent/OurArchitechcher/OurArchitechcher";
import OurCodeSample from "./subcomponent/OurCodeSample/OurCodeSample";

const DevelopersForHigher = () => {
  return (
    <>
      <div className="centerContainer container">
        <h1 className="tittleText">Our Offered Different Developers</h1>

        <div className="developer_container container">
          {getDevelopersDetails()?.map((item, idx) => {
            return (
              <button key={idx} className="developers_cart">
                <img src={item.image} alt="developerImage" />
                <h1>{item.name}</h1>
              </button>
            );
          })}
        </div>
      </div>
      <Technology />
      <LanguageAndIde/>
      <OurProjects/>
      <HowWeHire/>
      <EnsuringQualityOfCode/>
      <OurArchitechcher/>
      <OurCodeSample/>
      <SuccessFullDelivery/>
      <CorporationModel/>
    </>
  );
};

export default DevelopersForHigher;
