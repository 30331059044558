const demoData = [
  {
    name: "Healthcare analytics",
    points: [
      "Patient health condition monitoring, condition-based alerting.",
      "Patient treatment optimization.",
      "Assessment of patient risks and personalized care plan recommendations.",
      "Proactive care (defining trends and patterns in patient condition requiring a doctor’s attention).",
      "Fraud detection in healthcare insurance.",
      "Medical staff workload prediction and work shifts optimization.",
      "Optimization of clinical space and equipment usage.",
    ],
  },
  {
    name: "Banking analytics",
    points: [
      "Continuous monitoring of bank stability indicators.",
      "Institution performance forecasts.",
      "360-degree view of customers.",
      "Identifying cross-selling and upselling opportunities.",
      "Insights into customer service management.",
      "What-if modeling for timely mitigation of market, credit, and operational risks.",
      "Fraud detection.",
      "Automated compliance checks and non-compliance alerts.",
    ],
  },
  {
    name: "Insurance analytics",
    points: [
      "AI-powered insurance recommendations tailored for certain customer segments.",
      "Finance analytics with underwriting profitability monitoring and product-specific scenario modeling.",
      "Monitoring insurance-related risks with stress testing.",
      "Operational analytics insights for improving claims processing, customer service, and other internal processes.",
      "Workforce analytics for agents' performance assessment, sales incentive programs adjustment, and top talent retention.",
      "Insights into the performance of external agencies and partners.",
      "Predictive and prescriptive analytics for insurance planning and optimization.",
    ],
  },
  {
    name: "Lending analytics",
    points: [
      "Providing insights into borrowers' creditworthiness and predicting default and NPL risks.",
      "Lending products performance analytics.",
      "AI-powered recommendations for loan portfolio optimization.",
      "Identifying bottlenecks in underwriting, loan approval, servicing, debt collection, and other operational processes.",
      "Continuous compliance monitoring and non-compliance alerting.",
    ],
  },
  {
    name: "Investment analytics",
    points: [
      "Portfolio performance analytics with asset-specific benchmarking.",
      "Factor exposure, performance attribution, and risk attribution analysis.",
      "Continuous monitoring of market, credit, and liquidity risks.",
      "What-if scenarios under various risk factors and portfolio rebalancing options.",
      "Identifying insider trading, pump and dump schemes, HFT manipulation, and other kinds of fraud.",
      "Insights into tax management.",
      "Continuous compliance monitoring and alerting.",
    ],
  },
  {
    name: "Manufacturing analytics",
    points: [
      "Overall equipment effectiveness analysis and optimization.",
      "Manufacturing process quality optimization.",
      "Equipment maintenance scheduling.",
      "Power consumption forecasting and optimization.",
      "Production loss root cause analysis.",
    ],
  },
  {
    name: "Retail analytics",
    points: [
      "Retail business performance analysis, monitoring sales and profitability.",
      "Demand analysis and forecasting.",
      "Assortment and merchandising planning and optimization.",
      "Data-driven recommendations on optimal product promotion activities.",
    ],
  },
  {
    name: "Transportation and logistics analytics",
    points: [
      "Operational capacity planning and optimization based on the analysis of incoming shipments, customer delivery schedules, vehicles availability, and personnel shift schedules.",
      "Predictive analytics for vehicle maintenance (failure prediction, recommendation of maintenance actions, etc.).",
      "Vehicle demand forecasting.",
      "Predicting optimal amounts of fuel needed based on the analysis of driving patterns.",
      "IoT data analytics (data on cargo temperature, humidity, etc.; data on driver behavior, data on vehicle condition, etc.) for safe cargo delivery.",
    ],
  },
  {
    name: "Real estate analytics",
    points: [
      "Providing insights into market trends and analyzing property values to support informed investment decisions.",
      "Automated buyer-seller matching and customer-specific property recommendations.",
      "Comprehensive portfolio management with expenses tracking and cash-flow forecasts.",
      "Calculating and monitoring rental, occupancy rates, and other property performance KPIs.",
      "Multidimensional customer segmentation with AI-powered suggestions on segment-specific targeting.",
      "Predictive analytics to forecast property value or identify property that is likely to be sold or bought in the near future.",
    ],
  },
  {
    name: "Professional services analytics",
    points: [
      "Multidimensional customer segmentation to enable automated customer-agent matching, effective ad targeting, and personalized services.",
      "Analyzing customer sentiment and satisfaction for data-driven service improvement.",
      "Operational analytics to improve staff performance and process efficiency.",
      "Predictive analytics to forecast resource needs and optimize staff allocation.",
      "Financial analytics based on internal and external data to detect revenue leakage and improve business profitability.",
    ],
  },
  {
    name: "Energy & utilities analytics",
    points: [
      "Continuous monitoring of energy generation and distribution with AI-powered optimization recommendations.",
      "Analyzing renewable energy share in the energy grid.",
      "Operational analytics to improve staff performance and process efficiency.",
      "Predictive and preventive maintenance analytics.",
      "Analyzing energy consumption patterns to enable efficient resource allocation.",
      "Predictive analytics to forecast energy demand.",
    ],
  },
  {
    name: "Oil & gas analytics",
    points: [
      "Exploration management analytics to identify optimal drilling locations and estimate oil and gas reserves.",
      "Predictive analytics for estimated ultimate recovery and production rates forecasting.",
      "Equipment predictive and preventive maintenance.",
      "Environmental impact insights.",
      "Real-time monitoring of processes and assets (e.g., production, transportation, pipelines, storage tanks, pumping sections) with immediate alerting.",
      "Refinery optimization and quality control analytics.",
      "Insights into supply chain management.",
    ],
  },
  {
    name: "Telecoms analytics",
    points: [
      "Continuous network performance monitoring and analytics to forecast excess capacity areas and optimize network capacity.",
      "Providing insights into customer management to foresee and prevent churn, tailor offerings, and increase customer retention.",
      "Identifying operational bottlenecks and providing AI-powered optimization recommendations.",
    ],
  },
  {
    name: "Education analytics",
    points: [
      "Analyzing students' and parents' feedback on the quality of teaching and the learning environment to improve the service.",
      "Students' performance analytics with alerts on potential intervention.",
      "Insights into the patterns of learning platforms usage to enhance teaching and learning outcomes.",
      "Financial analytics with insights into grant revenue, endowment value, cash balance, wages, and more.",
      "Analyzing teachers' performance and providing insights into top talent attraction and retention.",
    ],
  },
  {
    name: "Travel and hospitality",
    points: [
      "Tracking and analyzing customer interactions, preferences, and feedback to optimize customer relationship management.",
      "Operational analytics, including service quality and employee performance analysis.",
      "Providing real-time personalized recommendations on destinations, lodging options, events, etc.",
      "AI-powered pricing optimization based on market demand, individual customer preferences, and predicted price movements.",
      "Analyzing results of promotions, discounts, loyalty programs and their influence on business profitability.",
      "Forecasting demand to maximize revenue from hotel rooms, flights, and other related services.",
    ],
  },
  {
    name: "Media & entertainment analytics",
    points: [
      "Multidimensional audience segmentation with granular comparisons, e.g., demographic breakdown vs. genre audience.",
      "Audience engagement analytics with insights into cross-platform customer behavior and preferences.",
      "Real-time personalized content recommendations.",
      "Content performance analytics.",
      "Forecasting content demand and popularity, including box office success.",
      "Tracking the effectiveness of advertising and marketing campaigns with real-time targeting adjustment.",
      "Tracking compliance with regulatory standards, including those related to personal data handling.",
    ],
  },
];
const demoData2 = [
  {
    name: "Financial analytics",
    points: [
      "Monitoring revenue, expenses and profitability of a company.",
      "Profitability analysis and financial performance management.",
      "Budget planning, formulating long-term business plans.",
      "Financial risk forecasting and management.",
    ],
  },
  {
    name: "Supply chain analytics",
    points: [
      "Identifying demand drivers, consumer demand forecasting and planning.",
      "Supplier performance monitoring and evaluation.",
      "Predictive route optimization.",
      "Determining the optimal level of inventory to meet the demand and prevent stockouts, inventory planning and management.",
      "Identifying patterns and trends throughout the supply chain for enhanced supply chain risks management.",
    ],
  },
  {
    name: "Sales and product analytics",
    points: [
      "Sales channel analytics.",
      "Pricing analytics to design pricing strategies.",
      "Identifying and predicting sales trends.",
      "Conducting product performance analysis.",
      "Tracking customer interactions with a product to identify pain points leading to churn.",
      "Conducting competitor benchmarking.",
    ],
  },
  {
    name: "Customer analytics",
    points: [
      "Customer behavior analysis and predictive modeling.",
      "Customer segmentation for tailored sales and marketing campaigns.",
      "Personalized cross-selling and upselling offers for extended customer lifetime value.",
      "Predicting customer attrition and customer churn risk management.",
      "Customer sentiment analysis.",
    ],
  },
  {
    name: "Asset analytics",
    points: [
      "Real-time asset monitoring and tracking.",
      "Predictive and preventive maintenance, developing asset maintenance strategies.",
      "Planning asset investments.",
      "Asset usage analytics, planning and scheduling asset modernization/replacement/disposal strategies.",
    ],
  },
  {
    name: "HR analytics",
    points: [
      "Employee/department performance monitoring and analysis.",
      "Employee experience and satisfaction analysis.",
      "Employee retention strategy optimization and management.",
      "Employee hiring strategy analysis and optimization.",
      "Labor cost analytics.",
    ],
  },
];

export const getIndustryAnalytics=()=>{
  return demoData
}

export const getAnalyticsArea=()=>{
  return demoData2
}