import React from 'react';
import { HiArrowLongRight } from "react-icons/hi2";
import "./style.css"
import getProjectDemo from './DemoData/DemoData';
const OurProjects = () => {
    return (
        <div className='centerContainer container'>
             <h1 className="tittleText">Our Projects</h1>
             <div className='projectContainer'>
                {
                    getProjectDemo()?.map((item,idx)=>{
                        return(
                            <div className='projectCard'>
                                <img alt='projectimage' src={item.img}/>
                                <div style={{backgroundColor:item.color}} className='flotContainer'>
                                    <div style={{display:"flex",gap:"10px"}}>
                                        {
                                            item.key?.map((item,idx)=><span style={{backgroundColor:"white",padding:"4px",borderRadius:"2px",fontSize:"14px",fontWeight:"500"}}>{item}</span>)
                                        }
                                    </div>
                                    <h1>{item.name}</h1>
                                    <p>{item.description}</p>
                                    <button >
                                        <span>See Project Details</span>
                                    <HiArrowLongRight style={{marginLeft:"10px"}} />
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
             </div>
        </div>
    );
};

export default OurProjects;