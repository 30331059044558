// import React, { useEffect } from "react";
// import ServiceComponent from "./ServiceComponent";

// import Aos from "aos";
// import "aos/dist/aos.css";

// const Service = () => {
//   useEffect(() => {
//     Aos.init({
//       easing: "ease-in-sine",
//     });
//   }, []);
//   // const [loading, setIsloading] = useState(false);

//   return (
//     <div
//       className="container-fluid d-flex justify-content-center "
//       style={{ backgroundColor: "#eef3ff" }}>
        
//       <div className="sectionContainer  sectionMergin ">
//         <div className="row">
//           <div
//             className="col-md-12 d-flex justify-content-center"
//             data-aos="fade-up"
//             style={{
//               color: "black",
//               fontWeight: "500",
//               fontFamily: "Montserrat",
//               fontSize: "32px",
//             }}
//           >
//             <p className=" text-center  fs-1 mt-2 mb-3 ">Services We Offer</p>
//           </div>
//           <div className="col-md-12 " data-aos="fade-up">
//             <ServiceComponent />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Service;

import React, { useEffect, useState } from "react";
import ServiceComponent from "./ServiceComponent";
import Aos from "aos";
import "aos/dist/aos.css";

const Service = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);

  const [loading, setLoading] = useState(true); 

  setTimeout(() => {
    setLoading(false); 
  }, 2000);

  return (
    <div className="container-fluid d-flex justify-content-center" style={{ backgroundColor: "#eef3ff" }}>
      {loading ? (
        <div>
          <img
            src={require("./../../../Assets/Images/sq-lite-filled.png")}
            className="full-width-image"
            width="80"
            height="80"
          />
        </div>
      ) : (
        <div className="sectionContainer sectionMergin">
          <div className="row">
            <div
              className="col-md-12 d-flex justify-content-center"
              data-aos="fade-up"
              style={{
                color: "black",
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "32px",
              }}
            >
              {/* <p className="text-center fs-1 mt-3 mb-3">Services We Offer</p> */}
              <h2 className="text-center fs-1 mt-5">Services We Offer</h2>
            </div>
            <p className="px-5 py-3 text-center text-lg-start" style={{ fontSize: "1.2rem", fontWeight: "450" }}>At TZAZ INFOTECH, we are a top-tier software development agency in London offering a wide range of software development and consulting services to help your business thrive. Our expertise includes Custom Software Development, Web Development, and Mobile App Development. We also specialize in Data Analytics and Software Consulting, ensuring your business remains competitive. Additionally, our Software Developers for Hire provide you with the talent to scale your projects efficiently.</p>
            <div className="col-md-12" data-aos="fade-up">
              <ServiceComponent />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Service;
