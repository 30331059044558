import React from 'react';
import './NavBar.css'
import logo from './../../../Assets/Images/Logo-removebg-preview.png'
import { NavLink, useNavigate } from 'react-router-dom';

function NavBar(props) {

    const navigate = useNavigate()
    return (
        <div className='sticky'>
            <div className='container'>
                <nav className="navbar navbar-expand-lg   ps-md-5 pe-md-5 navbar-custom ">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/"><img src={logo} alt="" style={{ width: "6rem",marginRight:"10px" }} />
                        {/* TZAZ INFOTECH */}
                        
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 customNav">
                                <li className="nav-item">
                                    <a className="nav-link " aria-current="page" onClick={() => {
                                        navigate("/")
                                    }}>Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " aria-current="page"
                                        onClick={() => {
                                            navigate("/Career")
                                        }}>Career</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link " aria-current="page"
                                        onClick={() => {
                                            navigate("/Article")
                                        }}>Articles</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link"  aria-current="page"
                                        onClick={() => {
                                            navigate("/our-services")
                                        }}>Services</a>


                                        {/* hover dropdown container */}
                                        <ul className='dropdownContainer'>
                                            <NavLink to={"/custom-software-development-services"}>Custom Software Development</NavLink>
                                            <NavLink to={"/developers-for-hire-services"}>Developers for Hire</NavLink>
                                            <NavLink to={"/web-development-services"}>Web Development</NavLink>
                                            <NavLink to={"/software-consulting-services"}>Software Consulting</NavLink>
                                            <NavLink to={"/data-analytics-services"}>Data Analytics</NavLink>
                                            <NavLink to={"/mobile-app-development-services"}>Mobile App Development</NavLink>
                                          
                                        </ul>
                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>
            </div>
        </div>

    );
}

export default NavBar;

// ) Custom Software Development 2) Developers for Hire 3) Web Development 4) Software Consulting 5) Data Analytics 6) Mobile App Development
