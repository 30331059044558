import img1 from "../../../../../../../Assets/Images/ProjectImage/ProjectImage/AdminProjectTurkey/CourseSale.png"
import img2 from "../../../../../../../Assets/Images/ProjectImage/ProjectImage/GoTaste/Client/HomePage.png"
import img3 from "../../../../../../../Assets/Images/ProjectImage/ProjectImage/ModinaFrontend/HomePage.png"
import img4 from "../../../../../../../Assets/Images/ProjectImage/ProjectImage/Pantomime/Client/Home1.png"
import img5 from "../../../../../../../Assets/Images/ProjectImage/ProjectImage/Infinigent/RolesAndPermissions.png"
import img6 from "../../../../../../../Assets/Images/ProjectImage/ProjectImage/Sundarban/1.png"

const demoData=[
    
    {
        name:"Course Sale",
        description:"Add Later",
        key:["Educational","IT"],
        img:img1,
        color:"#fafade"
    },
    {
        name:"Go Taste",
        description:"Add Later",
        key:["Food","Restaurent"],
        img:img2,
        color:"#def3fa" 
    },
    {
        name:"Modina Traders",
        description:"Add Later",
        key:["Industry","Business"],
        img:img3,
        color:"#f5effe"
    },
    {
        name:"Pantomime",
        description:"Add Later",
        key:["Education" ,"Edu-Tech"],
        img:img4,
        color:"#defaee"
    },
    {
        name:"Infinigent",
        description:"Add Later",
        key:["Business","Management"],
        img:img5,
        color:"#fafade"
    },
    {
        name:"Sundarban",
        description:"Add Later",
        key:["Business","Management"],
        img:img6,
        color:"#FFEBEE"
    },
]


const getProjectDemo=()=>{
    return demoData
}
export default getProjectDemo