import React, { useEffect, useState } from "react";
import ServiceImg from "./../../../Assets/Images/BusinessAnalysis.jpg";
import dbImg from "./../../../Assets/Images/Business intelligence.jpg";
import appImg from "./../../../Assets/Images/COMMUNICATIONS TECHNOLOGY.jpg";
import erpImg from "./../../../Assets/Images/Consulting.jpg";
import dataImg from "./../../../Assets/Images/Data analytics.jpg";
import infoImg from "./../../../Assets/Images/Datainformation management.jpg";

import axios from "axios";
import "./ServicesComponent.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import Aos from "aos";

const ServiceComponent = () => {
  const [serviceData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllData();

    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);

  const getAllData = async () => {
    setLoading(true);
    await axios
      .get(
        "https://testwebapiqttzaz.azurewebsites.net/api/Service/getAllService"
      )
      .then((response) => {
        setData(response.data.slice(0, 8));
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <img src={require("./../../../Assets/Images/Loader.gif")} />
      </div>
    );
  } else {
    return (
      <div>
        <div className="container">
          <div className="serviceContainer">
            {serviceData.map((d, i) => {
              return (
                <div className=" mt-4" data-aos="fade-up">
                  <div className="card serviceCard h-100 card-custom-product full-width-image-services">
                    <div className="">
                      <img
                        src={require(`./../../../Assets/Images/${d.serviceLogo}`)}
                        // src={require(`./../../../Assets/Images/mobile_development.png`)}
                        className="card-img-top img-product"
                        alt={d.serviceImage}
                      />
                    </div>

                    <div className="card-body ">
                      {d.slug != undefined ? (
                        <Link
                          to={"/" + d.slug}
                          className="serviceName"
                          // reloadDocument={true}
                        >
                          <p
                            className="card-title  fw-bold  
     "
                          >
                            {d.serviceName}
                          </p>
                        </Link>
                      ) : (
                        <p
                          // className="card-title fs-2 fw-semibold text-uppercase "
                          className="card-title  fw-bold "
                          style={{ color: "#0b4040" }}
                        >
                          {d.serviceName}
                        </p>
                      )}

                      <p
                        // className="card-text text-uppercase "
                        className="card-text "
                      >
                        <span className=" me-3 cardText ">
                          {" "}
                          {d.serviceDescription}
                        </span>
                      </p>
                    </div>
                    <div className="text-start ms-3">
                      {d.slug != undefined ? (
                        <Link
                          to={"/" + d.slug}
                          className="serviceName"
                          // reloadDocument={true}
                        >
                          See More{" "}
                          <img
                            src={require("./../../../Assets/Images/right-arrow.png")}
                            alt=""
                            style={{ width: "1rem" }}
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row mt-3">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <Link to={"/our-services"} className=" btn-see-more" style={{textAlign:"center",textDecoration:"none",borderRadius:"10px"}}>
                See More
              </Link>
            </div>
          </div>

          {/* <div className="col-md-6 col-lg-4 mb-5 " >
              <div className="card serviceCard card-custom-product">
                <div className="">
                  <img
                    src={ServiceImg}
                    className="card-img-top img-product"
                    alt="Service Image"
                  />
                </div>
    
                <div className="card-body ">
                  <p className="card-title fs-4 fw-semibold text-uppercase ">
                    Cloud Services
                  </p>
                  <p className="card-text text-uppercase ">
                    <span className=" me-3 cardText"> CLOUD OUTSOURCING PROVIDER MANAGED CLOUD SERVICES FOR BUSINESSES</span>
                    <span>
                      <a
                        href={ServiceImg}
                        className=" readMore text-dark fw-semibold"
                      >
    
                      </a>
                    </span>
                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-5 " >
              <div className="card serviceCard card-custom-product">
                <img
                  src={dbImg}
                  className="card-img-top img-product"
                  alt="Service Image"
                />
                <div className="card-body ">
                  <p className="card-title fs-4 fw-semibold text-uppercase ">
                    Database Management
                  </p>
                  <p className="card-text text-uppercase ">
                    <span className=" me-3 cardText"> DBMS establish the relationship between datasets, and how users interact with them.
    
                    </span>
                    <span>
                      <a
                        href={ServiceImg}
                        className=" readMore text-dark fw-semibold"
                      >
    
                      </a>
                    </span>
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-5 " >
              <div className="card  card-custom-product h-100 background-white-color">
                <img
                  src={appImg}
                  className="card-img-top img-product"
                  alt="Service Image"
                />
                <div className="card-body ">
                  <p className="card-title fs-4 fw-semibold text-uppercase ">
                    Mobile Application
                  </p>
                  <p className="card-text text-uppercase ">
                    <span className=" me-3 cardText"> We build your apps for all the devices out there.</span>
                    <span>
                      <a
                        href={ServiceImg}
                        className=" readMore text-dark fw-semibold"
                      >
    
                      </a>
                    </span>
                  </p>
                  
                </div>
              </div>
            </div> */}
        </div>
      </div>
    );
  }
};

export default ServiceComponent;
