import "./WebDevelopment.css"
import OurProjects from "../DevelopersForHigher/subcomponent/OuerProjects/OurProjects";
function WebDevelopment() {

  return (
    <>
    <div className="container">

      <div className="web-development">
        <div className="toppart text-center">
          <h2 className="game-feature-heading1 mb-5">Our Web Development  Services</h2>

        </div>
        <div className="box">
          <div className="row">
            <div className="col-12">

              <ul className="row d-flex justify-content-between p-0">

                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/laptop.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Custom Website Development</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/online-shop.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">E-commerce Website Development</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/browsing.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Progressive Web Applications (PWAs)</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/cms.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Content Management System (CMS) Development</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/feature-selection.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Web Application Development</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/ui.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">UI/UX Design Services</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/maintenance.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Web Maintenance & Support</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/api.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">API Integration and Development</h5>

                  </a>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center my-2"
                      style={{ height: "4rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/cloud-computing.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Cloud-Based Web Solutions</h5>

                  </a>

                </li>
              </ul>


            </div>
          </div>
        </div>
      </div>

      {/* ----------------------Our Web Development Technologies------------------------------ */}

      <div className="technology-section">
        <div className=" container-fluid d-flex justify-content-center  align-items-center ">
          <div className="sectionContainer ">
            <div className="row " data-aos="fade-up">
              <div className="col-md-12 d-flex justify-content-center mt-30  ">
                {/* <h2 className="text-center fw-bold fs-1 mb-5 ">
                Technology We Use
              </h2> */}
                <h2 className="text-center  fs-1 mb-5 tech-title ">
                  Our Web Development Technologies
                </h2>
              </div>
            </div>
            <div>
              <div
                className={`technologypart`}
              >
                <div className="technology" style={{ flexWrap: "wrap" }}>

                  <div className="tech">
                    <img
                      className="img-resize"
                      src="https://www.techgropse.com/common/images/updated/.net.svg"
                      alt=""
                    />
                    <p>.Net</p>
                  </div>

                  <div className="tech">
                    <img
                      className="img-resize"
                      src="https://www.techgropse.com/common/images/updated/nodejs.svg"
                      alt=""
                    />
                    <p>Node.Js</p>
                  </div>
                  <div className="tech">
                    <img
                      className="img-resize"
                      src={require("../../../../Assets/Images/blazor_black.png")}
                      alt=""
                    />
                    <p>Blazor</p>
                  </div>
                  <div className="tech">
                    <img
                      className="img-resize"
                      src="https://www.techgropse.com/common/images/updated/react.svg"
                      alt=""
                    />
                    <p>React.Js</p>
                  </div>
                  <div className="tech">
                    <img
                      className="img-resize"
                      src="https://www.techgropse.com/common/images/updated/python.svg"
                      alt=""
                    />
                    <p>Python</p>
                  </div>
                  <div className="tech">
                    <img
                      className="img-resize"
                      src="https://www.techgropse.com/common/images/updated/html5.svg"
                      alt=""
                    />
                    <p>HTML 5</p>
                  </div>
                  <div className="tech">
                    <img
                      className="img-resize"
                      src={require("../../../../Assets/Images/css-3.png")}
                      alt=""
                    />
                    <p>CSS 3</p>
                  </div>
                  <div className="tech">
                    <img
                      className="img-resize"
                      src={require("../../../../Assets/Images/typescript.png")}
                      alt=""
                    />
                    <p>TypeScript</p>
                  </div>
                  <div className="tech">
                    <img
                      className="img-resize"
                      src={require("../../../../Assets/Images/java-script.png")}
                      alt=""
                    />
                    <p>JavaScript</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <OurProjects />



      {/* -----------------------Why Choose Our Web Development Services---------------------------------- */}
      <div className="container">
      <div className="web-development">
        <div className="toppart text-center">
          <h2 className="game-feature-heading1 mb-5">Why Choose Our Web Development Services?</h2>
          <p className="text-center mb-5 text-lg-start ">
            Choosing our web development services means partnering with a team dedicated to delivering high-performing, secure, and scalable web solutions tailored to your business needs. Our developers use the latest technologies to create user-friendly websites that enhance your online presence. We focus on responsive design, ensuring your site looks great on all devices. With our attention to detail and commitment to deadlines, we guarantee a seamless process from concept to launch.
          </p>


        </div>
        <div className="box">
          <div className="row">
            <div className="col-12">

              <ul className="row d-flex justify-content-between p-0">
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12  justify-content-center my-2"
                    style={{ height: "5rem" }}
                  >
                    <img
                      className=""
                      src={require("../../../../Assets/Images/solution.png")}
                    ></img>
                  </div>
                  <h5 className="my-3 fw-bold">Custom, Scalable Web Solutions</h5>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12  justify-content-center my-2"
                    style={{ height: "5rem" }}
                  >
                    <img
                      className=""
                      src={require("../../../../Assets/Images/mobile.png")}
                    ></img>
                  </div>
                  <h5 className="my-3 fw-bold">Responsive and Mobile-first Design</h5>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12  justify-content-center my-2"
                    style={{ height: "5rem" }}
                  >
                    <img
                      className=""
                      src={require("../../../../Assets/Images/ai.png")}
                    ></img>
                  </div>
                  <h5 className="my-3 fw-bold">Latest technologies and trends</h5>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12  justify-content-center my-2"
                    style={{ height: "5rem" }}
                  >
                    <img
                      className=""
                      src={require("../../../../Assets/Images/sand-timer.png")}
                    ></img>
                  </div>
                  <h5 className="my-3 fw-bold">Commitment to Deadlines</h5>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12  justify-content-center my-2"
                    style={{ height: "5rem" }}
                  >
                    <img
                      className=""
                      src={require("../../../../Assets/Images/team.png")}
                    ></img>
                  </div>
                  <h5 className="my-3 fw-bold">Strong Post-launch Support</h5>

                </li>
                <li className=" border-right full-width-image-web-development mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12  justify-content-center my-2"
                    style={{ height: "5rem" }}
                  >
                    <img
                      className=""
                      src={require("../../../../Assets/Images/24-hour-service.png")}
                    ></img>
                  </div>
                  <h5 className="my-3 fw-bold">24/7 Technical Support</h5>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </div>
    </>
  )

}

export default WebDevelopment;