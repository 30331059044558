import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Teschnology.css";

import Aos from "aos";
import "aos/dist/aos.css";

import flutter from "../../../../src/Assets/Icons/technology/flutter.png";
import xamarin from "../../../../src/Assets/Icons/technology/xamarin.png";
import ojbc from "../../../../src/Assets/Icons/technology/ojbc.png";
import xd from "../../../../src/Assets/Icons/technology/xd.png";
import framer from "../../../../src/Assets/Icons/technology/framer.png";
import afinity from "../../../../src/Assets/Icons/technology/afinity.png";
import aws from "../../../../src/Assets/Icons/technology/aws.png";
import heroku from "../../../../src/Assets/Icons/technology/heroku.png";
import digitalocean from "../../../../src/Assets/Icons/technology/do.png";
import vercel from "../../../../src/Assets/Icons/technology/vercel.svg";
import mdb from "../../../../src/Assets/Icons/technology/mongodb.png";
import postgresql from "../../../../src/Assets/Icons/technology/postsql.png";
import redis from "../../../../src/Assets/Icons/technology/redis.png";


const Technology = () => {
  const [techData, setData] = useState([]);
  const [technologyData, settechnologyData] = useState(1);

  useEffect(() => {
    axios
      .get(
        "https://testwebapiqttzaz.azurewebsites.net/api/Technology/getAllTechnology"
      )
      .then((response) => {
        setData(response.data);
      });
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <div className="technology-section">
      <div className=" container-fluid d-flex justify-content-center  align-items-center ">
        <div className="sectionContainer ">
          <div className="row " data-aos="fade-up">
            <div className="col-md-12 d-flex justify-content-center mt-30 container ">
              {/* <h2 className="text-center fw-bold fs-1 mb-5 ">
                Technology We Use
              </h2> */}
              <h1 className="tittleText">Our Developers' Core Competencies</h1>
            </div>
          </div>
          {/* <div className="row" data-aos="fade-up">
            <div className="d-flex justify-content-center">
              <div className="col-md-10">
                <div className="d-flex justify-content-center pb-4">
                  <h6 className=" fw-semibold text-center ">
                    It has become a prerequisite for companies to develop custom
                    software products to stay competitive. TZAZ INFOTECH’s
                    technical expertise use the most popular technology for
                    development outsourcing software.
                  </h6>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="row ">
            {techData.map((data) => {
              return (
                <div className="col-lg-2 col-4 mb-4 " key={data.name} data-aos="fade-up">
                  <div className="technologyBox d-flex justify-content-center d-flex align-items-center bg-white">
                    <div className="technologyImage">
                      <img
                        // src={DotNet}
                        src={require(./../../../Assets/Images/${data.logo})}
                        alt={data.name}
                        className="technologyImage"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
          <div className="technologyTab">
            <h4
              className={`${
                technologyData === 1
                  ? "selected-technology"
                  : "dselected-technology"
              } cursor-pointer`}
              onClick={() => {
                settechnologyData(1);
              }}
            >
              Web Technology
            </h4>
            <h4
              className={`${
                technologyData === 2
                  ? "selected-technology"
                  : "dselected-technology"
              } cursor-pointer`}
              onClick={() => {
                settechnologyData(2);
              }}
            >
              Mobile Technology
            </h4>
            <h4
              className={`${
                technologyData === 3
                  ? "selected-technology"
                  : "dselected-technology"
              } cursor-pointer`}
              onClick={() => {
                settechnologyData(3);
              }}
            >
              UI/UX Design
            </h4>
            <h4
              className={`${
                technologyData === 4
                  ? "selected-technology"
                  : "dselected-technology"
              } cursor-pointer`}
              onClick={() => {
                settechnologyData(4);
              }}
            >
              Cloud
            </h4>
            <h4
              className={`${
                technologyData === 5
                  ? "selected-technology"
                  : "dselected-technology"
              } cursor-pointer`}
              onClick={() => {
                settechnologyData(5);
              }}
            >
              Database
            </h4>
          </div>
          <div>
            <div
              className={`technologypart ${
                technologyData == 1 ? "" : "d-none"
              } `}
            >
              <div className="technology" style={{ flexWrap: "wrap" }}>
                {/* <div className="tech">
                  <img  className="img-resize"src="https://www.techgropse.com/common/images/updated/php.svg" alt="" />
                  <p>PHP</p>
                </div> */}
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/.net.svg"
                    alt=""
                  />
                  <p>.Net</p>
                </div>
                {/* <div className="tech">
                  <img className="img-resize" src="https://www.techgropse.com/common/images/updated/laravel.svg" alt="" />
                  <p>Laravel</p>
                </div> */}
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/nodejs.svg"
                    alt=""
                  />
                  <p>Node.Js</p>
                </div>
                <div className="tech">
                  <img
                    className="img-resize"
                    // src="https://www.techgropse.com/common/images/updated/angular.svg"
                    src={require("./../../../Assets/Images/blazor_black.png")}
                    alt=""
                  />
                  <p>Blazor</p>
                </div>
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/react.svg"
                    alt=""
                  />
                  <p>React.Js</p>
                </div>
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/python.svg"
                    alt=""
                  />
                  <p>Python</p>
                </div>
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/html5.svg"
                    alt=""
                  />
                  <p>HTML 5</p>
                </div>
                <div className="tech">
                  {/* <img  className="img-resize" src="https://www.techgropse.com/common/images/updated/objective-c.svg" alt=""/> */}
                  <img
                    className="img-resize"
                    src={require("./../../../Assets/Images/css-3.png")}
                    alt=""
                  />
                  <p>CSS 3</p>
                </div>
                <div className="tech">
                  {/* <img  className="img-resize" src="https://www.techgropse.com/common/images/updated/objective-c.svg" alt=""/> */}
                  <img
                    className="img-resize"
                    src={require("./../../../Assets/Images/typescript.png")}
                    alt=""
                  />
                  <p>TypeScript</p>
                </div>
                {/* <div className="tech">
                  <img className="img-resize" src="https://www.techgropse.com/common/images/updated/vue.svg" alt="" />
                  <p>VueJS</p>
                </div> */}
              </div>
            </div>

            <div
              className={`technologypart ${
                technologyData == 2 ? "" : "d-none"
              }`}
            >
              <div className="technology" style={{ flexWrap: "wrap" }}>
                <div className="tech">
                  {/* <img  className="img-resize" src="https://www.techgropse.com/common/images/updated/objective-c.svg" alt=""/> */}
                  <img
                    className="img-resize"
                    src={require("./../../../Assets/Images/java (1).png")}
                    alt=""
                  />
                  <p>Java</p>
                </div>
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/kotlin.svg"
                    alt=""
                  />
                  <p>Kotlin</p>
                </div>
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/swift.svg"
                    alt=""
                  />
                  <p>Swift</p>
                </div>
                {/* <div className="tech">
                <img className="img-resize" src="https://www.techgropse.com/common/images/updated/flutter.svg" alt=""/>
                <p>Flutter</p>
              </div> */}
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/react.svg"
                    alt=""
                  />
                  <p>React</p>
                </div>
                <div className="tech">
                  <img className="img-resize" src={flutter} alt="" />
                  <p>Flutter</p>
                </div>


                <div className="tech">
                  <img className="img-resize" src={xamarin} alt="" />
                  <p>Xamarin</p>
                </div>

                <div className="tech">
                  <img className="img-resize" src={ojbc} alt="" />
                  <p>Objective-C</p>
                </div>


              </div>
            </div>
            <div
              className={`technologypart ${
                technologyData == 3 ? "" : "d-none"
              }`}
            >
              <div className="technology" style={{ flexWrap: "wrap" }}>
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/photoshop.png"
                    alt=""
                  />
                  <p>Photoshop</p>
                </div>
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/illustrator.png"
                    alt=""
                  />
                  <p>Illustrator</p>
                </div>
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/sketch.svg"
                    alt=""
                  />
                  <p>Sketch</p>
                </div>


                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/figma.svg"
                    alt=""
                  />
                  <p>Figma</p>
                </div>

                <div className="tech">
                  <img
                    className="img-resize"
                    src={xd}
                    alt=""
                  />
                  <p>Adobe XD</p>
                </div>

                <div className="tech">
                  <img
                    className="img-resize"
                    src={framer}
                    alt=""
                  />
                  <p>Framer</p>
                </div>

                <div className="tech">
                  <img
                    className="img-resize"
                    src={afinity}
                    alt=""
                  />
                  <p>Afinity</p>
                </div>



                {/* <div className="tech">
                  <img className="img-resize" src="https://www.techgropse.com/common/images/updated/xd.png" alt=""/>
                  <p>XD</p>
                </div> */}
              </div>
            </div>
            <div
              className={`technologypart ${
                technologyData == 4 ? "" : "d-none"
              }`}
            >
              <div className="technology" style={{ flexWrap: "wrap" }}>
                {/* <div className="tech">
                  <img className="img-resize" src="https://www.techgropse.com/common/images/updated/aws.svg" alt=""/>
                  <p>Amazon AWS</p>
                </div> */}
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/azure.svg"
                    alt=""
                  />
                  <p>Microsoft Azure</p>
                </div>
                {/* <div className="tech">
                  <img className="img-resize" src="https://www.techgropse.com/common/images/updated/googlecloud.svg" alt=""/>
                  <p>Google Cloud</p>
                </div> */}
                {/* <div className="tech">
                  <img className="img-resize" src="https://www.techgropse.com/common/images/updated/digitalocean.svg" alt=""/>
                  <p>Digital Ocean</p>
                </div> */}
                <div className="tech">
                  <img
                    className="img-resize"
                    src="https://www.techgropse.com/common/images/updated/firebase.svg"
                    alt=""
                  />
                  <p>Firebase</p>
                </div>


                <div className="tech">
                  <img
                    className="img-resize"
                    src={aws}
                    alt="aws"
                  />
                  <p>Aws</p>
                </div>

                <div className="tech">
                  <img
                    className="img-resize"
                    src={heroku}
                    alt="aws"
                  />
                  <p>Heroku</p>
                </div>

                <div className="tech">
                  <img
                    className="img-resize"
                    src={digitalocean}
                    alt="aws"
                  />
                  <p>DigitalOcean</p>
                </div>

                <div className="tech">
                  <img
                    className="img-resize"
                    src={vercel}
                    alt="aws"
                  />
                  <p>Vercel</p>
                </div>



              </div>
            </div>
            <div
              className={`technologypart ${
                technologyData == 5 ? "" : "d-none"
              }`}
            >
              <div className="technology" style={{ flexWrap: "wrap" }}>
                <div className="tech">
                  <img
                    className="img-resize"
                    // src="https://www.techgropse.com/common/images/updated/mysql.svg"
                    src={require("./../../../Assets/Images/my-sql-filled.png")}
                    alt=""
                  />
                  <p>MySQL</p>
                </div>
                <div className="tech">
                  {/* <img className="img-resize" src="https://www.techgropse.com/common/images/updated/mongodb.png" alt=""/> */}
                  <img
                    className="img-resize"
                    src={require("./../../../Assets/Images/sq-lite-filled.png")}
                    alt=""
                  />
                  <p>SQLite</p>
                </div>



                <div className="tech">
                  {/* <img className="img-resize" src="https://www.techgropse.com/common/images/updated/postgresql.svg" alt=""/> */}
                  <img
                    className="img-resize"
                    src={require("./../../../Assets/Images/ms-sql-server-filled.png")}
                    alt=""
                  />
                  <p>MS SQL</p>
                </div>
                
                <div className="tech">
                  {/* <img className="img-resize" src="https://www.techgropse.com/common/images/updated/postgresql.svg" alt=""/> */}
                  <img
                    className="img-resize"
                    src={mdb}
                    alt=""
                  />
                  <p>mongodb</p>
                </div>


                <div className="tech">
                  {/* <img className="img-resize" src="https://www.techgropse.com/common/images/updated/postgresql.svg" alt=""/> */}
                  <img
                    className="img-resize"
                    src={postgresql}
                    alt=""
                  />
                  <p>Postgre SQL</p>
                </div>


                <div className="tech">
                  {/* <img className="img-resize" src="https://www.techgropse.com/common/images/updated/postgresql.svg" alt=""/> */}
                  <img
                    className="img-resize"
                    src={redis}
                    alt=""
                  />
                  <p>Redis</p>
                </div>
                





                {/* <div className="tech">
                  <img className="img-resize" src="https://www.techgropse.com/common/images/updated/cassandra.svg" alt=""/>
                  <p>Cassandra</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technology;
