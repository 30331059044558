import "./MobileAppDevelopment.css"
import React, { useEffect, useState } from "react";
import axios from "axios";

import Aos from "aos";
import "aos/dist/aos.css";
import IndustriesSection from "../../../IndustriesWorkWith/Industries";
function MobileAppDevelopment() {
    const [techData, setData] = useState([]);
    const [technologyData, settechnologyData] = useState(1);

    useEffect(() => {
        axios
            .get(
                "https://testwebapiqttzaz.azurewebsites.net/api/Technology/getAllTechnology"
            )
            .then((response) => {
                setData(response.data);
            });
        Aos.init({
            easing: "ease-in-sine",
        });
    }, []);
    return (
        <div className="container">

            <div className="mobile-app">
                <div className="toppart text-center">
                    <h2 className="game-feature-heading1 mb-5">Our Mobile App Development  Services</h2>

                </div>
                <div className="box">
                    <div className="row">
                        <div className="col-12">

                            <ul className="row d-flex justify-content-between p-0">


                                <li className=" border-right full-width-image-mobile-app mobile-border-left mobile-border-right mobile-border-bottom">
                                    <a className="industrylinks">

                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "5rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/custom_app-development.png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-4 fw-bold">Custom mobile app development</h5>
                                        <p className="text-start p-3">Our Custom Mobile App Development services offers end-to-end solutions, from initial concept to deployment. We craft tailored iOS and Android apps with seamless functionality, intuitive design, and a focus on your business goals.</p>
                                    </a>

                                </li>
                                <li className=" border-right full-width-image-mobile-app mobile-border-left mobile-border-right mobile-border-bottom">
                                    <a className="industrylinks">

                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "5rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/android_dev.png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-4 fw-bold">Android app development</h5>
                                        <p className="text-start p-3">Our Android App Development service offers custom solutions using technologies like Java, Kotlin, and Flutter. From concept to launch, we build feature-rich, intuitive Android apps designed to deliver seamless performance and align perfectly with your objectives.</p>

                                    </a>

                                </li>
                                <li className=" border-right full-width-image-mobile-app mobile-border-left mobile-border-right mobile-border-bottom">
                                    <a className="industrylinks">

                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "5rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/apple.png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-4 fw-bold">iOS App Development</h5>
                                        <p className="text-start p-3">Our Custom Mobile App Development services offers end-to-end solutions, from initial concept to deployment. We craft tailored iOS and Android apps with seamless functionality, intuitive design, and a focus on your business goals.</p>
                                    </a>

                                </li>
                                <li className=" border-right full-width-image-mobile-app mobile-border-left mobile-border-right mobile-border-bottom">
                                    <a className="industrylinks">

                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "5rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/cross-platform (1).png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-4 fw-bold">Cross-Platform App Development</h5>
                                        <p className="text-start p-3">Our cross-platform app development service enables seamless creation of mobile applications for iOS and Android devices, with experienced cross-platform mobile app developers optimizing performance and user experience.</p>

                                    </a>

                                </li>
                            </ul>


                        </div>
                    </div>
                </div>
            </div>
            <IndustriesSection />

            <div className="advantages">
                <div className="toppart text-center">
                    <h2 className="game-feature-heading1 mb-5">Advantages of Mobile Software Development with TZAZ INFOTECH</h2>

                </div>
                <div className="box">
                    <div className="row">
                        <div className="col-12">

                            <ul className="row d-flex justify-content-between p-0">

                                <li className=" border-right full-width-image-advantages mobile-border-left mobile-border-right mobile-border-bottom">
                                    <a className="industrylinks">

                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "4rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/collaboration (1).png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-3 fw-bold">Cultural alignment</h5>
                                        <p className="px-lg-3 text-lg-start">A shared culture and language boost collaboration by improving communication and trust. This common ground fosters innovation and stronger team connections.</p>

                                    </a>

                                </li>
                                <li className=" border-right full-width-image-advantages mobile-border-left mobile-border-right mobile-border-bottom">
                                    <a className="industrylinks">

                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "4rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/dollar.png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-3 fw-bold">Cost-efficiency</h5>
                                        <p className="px-lg-3 text-lg-start">Leverage highly skilled talent to significantly lower operational costs, optimize resource allocation, and maximize return on investment, driving both efficiency and profitability for your business.</p>

                                    </a>

                                </li>
                                <li className="full-width-image-advantages mobile-border-left mobile-border-right mobile-border-bottom">
                                    <a className="industrylinks">

                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "4rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/organizational.png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-3 fw-bold">Collaborative Development</h5>
                                        <p className="px-lg-3 text-lg-start">Our nearshore team of English-proficient developers uses the latest collaboration tools to ensure seamless communication with your in-house staff.</p>

                                    </a>

                                </li>
                                <li className=" border-right full-width-image-advantages mobile-border-left mobile-border-right mobile-border-bottom">
                                    <a className="industrylinks">

                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "4rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/clock.png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-3 fw-bold">Compatible Time Zones</h5>
                                        <p className="px-lg-3 text-lg-start">Enable real-time collaboration with the agility needed for rapid software iterations, reducing delays and ensuring quick turnover</p>

                                    </a>

                                </li>
                                <li className=" border-right full-width-image-advantages mobile-border-left mobile-border-right mobile-border-bottom">
                                    <a className="industrylinks">

                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "4rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/map.png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-3 fw-bold">Geographical Proximity</h5>
                                        <p className="px-lg-3 text-lg-start">Shorter travel times enable frequent in-person meetings, fostering strong relationships</p>

                                    </a>

                                </li>
                                <li className="full-width-image-advantages mobile-border-left mobile-border-right mobile-border-bottom">

                                    <a className="industrylinks">
                                        <div
                                            className="d-flex col-md-12  justify-content-center my-2"
                                            style={{ height: "4rem" }}
                                        >
                                            <img
                                                className=""
                                                src={require("../../../../Assets/Images/favorites.png")}
                                            ></img>
                                        </div>
                                        <h5 className="my-3 fw-bold">Quality of Talent</h5>
                                        <p className="px-lg-3 text-lg-start">Thanks to our stellar reputation and excellent internal culture, we’re able to hire the top 1% of tech talent in the industry.</p>
                                    </a>

                                </li>

                            </ul>


                        </div>
                    </div>
                </div>
            </div>
            <div className="technology-section">
                <div className=" container-fluid d-flex justify-content-center  align-items-center ">
                    <div className="sectionContainer ">
                        <div className="row " data-aos="fade-up">
                            <div className="col-md-12 d-flex justify-content-center mt-30  ">

                                <h2 className="text-center  fs-1 mb-5 tech-title ">
                                    Tools & Technology

                                </h2>
                            </div>
                        </div>

                        <div className="technologyTab">
                            <h4
                                className={`${technologyData === 1
                                    ? "selected-technology"
                                    : "dselected-technology"
                                    } cursor-pointer`}
                                onClick={() => {
                                    settechnologyData(1);
                                }}
                            >
                                Platforms & Frameworks
                            </h4>
                            <h4
                                className={`${technologyData === 2
                                    ? "selected-technology"
                                    : "dselected-technology"
                                    } cursor-pointer`}
                                onClick={() => {
                                    settechnologyData(2);
                                }}
                            >
                                Programming Language
                            </h4>

                         
                            <h4
                                className={`${technologyData === 5
                                    ? "selected-technology"
                                    : "dselected-technology"
                                    } cursor-pointer`}
                                onClick={() => {
                                    settechnologyData(5);
                                }}
                            >
                                Database
                            </h4>
                        </div>
                        <div>
                            <div
                                className={`technologypart ${technologyData == 1 ? "" : "d-none"
                                    } `}
                            >

                                <div className="technology" style={{ flexWrap: "wrap" }}>

                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src="https://www.techgropse.com/common/images/updated/azure.svg"
                                            alt=""
                                        />
                                        <p>Microsoft Azure</p>
                                    </div>

                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src="https://www.techgropse.com/common/images/updated/kotlin.svg"
                                            alt=""
                                        />
                                        <p>Kotlin</p>
                                    </div>

                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src="https://www.techgropse.com/common/images/updated/.net.svg"
                                            alt=""
                                        />
                                        <p>.Net</p>
                                    </div>

                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src="https://www.techgropse.com/common/images/updated/react.svg"
                                            alt=""
                                        />
                                        <p>React</p>
                                    </div>
                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src={require("../../../../Assets/Images/android-studio-500.png")}
                                            alt=""
                                        />
                                        <p>Android Stdio</p>
                                    </div>

                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src="https://www.techgropse.com/common/images/updated/firebase.svg"
                                            alt=""
                                        />
                                        <p>Firebase</p>
                                    </div>


                                </div>
                            </div>

                            <div
                                className={`technologypart ${technologyData == 2 ? "" : "d-none"
                                    }`}
                            >
                                <div className="technology" style={{ flexWrap: "wrap" }}>
                                    <div className="tech">

                                        <img
                                            className="img-resize"
                                            src={require("../../../../Assets/Images/java (1).png")}
                                            alt=""
                                        />
                                        <p>Java</p>
                                    </div>

                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src="https://www.techgropse.com/common/images/updated/swift.svg"
                                            alt=""
                                        />
                                        <p>Swift</p>
                                    </div>
                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src={require("../../../../Assets/Images/Go-Logo_Black.png")}
                                            alt=""
                                        />
                                        <p>Golang</p>
                                    </div>
                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src={require("../../../../Assets/Images/js (1).png")}
                                            alt=""
                                        />
                                        <p>JavaScript</p>
                                    </div>
                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src="https://www.techgropse.com/common/images/updated/nodejs.svg"
                                            alt=""
                                        />
                                        <p>Node.Js</p>
                                    </div>
                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src={require("../../../../Assets/Images/c-sharp-logo-512.png")}
                                            alt=""
                                        />
                                        <p>C#</p>
                                    </div>                                  
                                    <div className="tech">
                                        <img
                                            className="img-resize"
                                            src="https://www.techgropse.com/common/images/updated/python.svg"
                                            alt=""
                                        />
                                        <p>Python</p>
                                    </div>


                                </div>
                            </div>

                            <div
                                className={`technologypart ${technologyData == 5 ? "" : "d-none"}`}
                            >
                                <div className="technology" style={{ flexWrap: "wrap" }}>
                                    <div className="tech">
                                        <img
                                            className="img-resize"

                                            src={require("../../../../Assets/Images/my-sql-filled.png")}
                                            alt=""
                                        />
                                        <p>MySQL</p>
                                    </div>
                                    <div className="tech">

                                        <img
                                            className="img-resize"
                                            src={require("../../../../Assets/Images/sq-lite-filled.png")}
                                            alt=""
                                        />
                                        <p>SQLite</p>
                                    </div>
                                    <div className="tech">

                                        <img
                                            className="img-resize"
                                            src={require("../../../../Assets/Images/ms-sql-server-filled.png")}
                                            alt=""
                                        />
                                        <p>MS SQL</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default MobileAppDevelopment;