import React, { useEffect, useState } from "react";
import Go5050 from "./../../Assets/Images/go5050logo2 1.png";
import axios from "axios";
import ArticleKeyword from "./ArticleKeyword";
import NavBar from "../HomePage/NavBar/NavBar";
import Footer from "../HomePage/Footer/Footer";
import articleImg from './../../Assets/Images/8767132.jpg'
import Aos from "aos";
import 'aos/dist/aos.css'

function AllArticle() {
  const [articleData, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://testwebapiqttzaz.azurewebsites.net/api/Article/getAllArticle")
      .then((response) => {
        setData(response.data);
      });
    Aos.init({
      easing: 'ease-in-sine'
    })
  }, []);
  return (
    <div>
      <NavBar />
      <div>
        <div className="">
          <div className="sectionMergin bannerHeight d-flex justify-content-center "></div>

          <div className=" container-fluid h-100 pt-5 pb-5">
            <div className="row  d-flex justify-content-center align-items-center bannerRow" >
              <div className="col-md-6  d-flex justify-content-center" style={{ flexDirection: "column" }} data-aos="fade-up">
                <div className="ms-5  careerText">
                  <h1 className="">Recent Articles</h1>
                  <p className="  careerTextP ">
                    Get updated with our latest software development news and
                    events
                  </p>
                </div>

              </div>
              <div className="col-md-6 d-flex justify-content-center" data-aos="fade-up">
                {/* <div className="bannerImageForCareer">
                  <img src={articleImg} alt="" className="img-fluid img-article" />
                </div> */}

              </div>
            </div>
          </div>

          <div className="sectionMergin">
            <div className="d-flex justify-content-center  ">
              <div className="container-fluid  sectionContainer">
                <div className="row pb-5" style={{ gap: "20px" }}>
                  {articleData.map((data) => {
                    return (
                      <div className="col-md-4 Custom-card-design " data-aos="fade-up" key={data.id}>
                        <div className="col-md-12 d-flex justify-content-center">
                          <div className="ArticleCardContainer">
                            <div className="d-flex justify-content-center ArticleCardImageContainer">
                              <img
                                src={data.imageLocation}
                                alt={data.imageAlt}
                                className="ArticleCardImage"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 pb-3 d-flex justify-content-center">
                          <ArticleKeyword id={data.id} />
                        </div>

                        <div className="col-md-12">
                          <div className="ArticleDetailsContainer">
                            <div>
                              <h3>{data.metaTitle}</h3>
                              <p>{data.metaDescription}</p>
                              {/* <div className="d-flex justify-content-end">
                                <div className="readMore">Read More</div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllArticle;
