import React, { useEffect, useState } from "react";
import Review from "../../../HomePage/Review/Review";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import "../ServiceDetailsView.css";
import "./CustomSoftware.css"
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";


function CustomSoftware() {

  return (
    <div class="container">

      <div className="custom-soft">
        <div className="toppart text-center">
          <h2 className="game-feature-heading1">Whom We Serve</h2>
        </div>
        <div className="box">
          <div className="row">
            <div className="col-12">
              <ul className="row d-flex justify-content-between p-0">
                <li className=" border-right full-width-image-custom-soft mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">


                    <h5 className="mb-4 fw-bold">For IT Managers</h5>
                    <div
                      className="d-flex col-md-12  justify-content-center serve-img"
                      style={{ height: "18rem" }}
                    >
                      <img
                        className="img-fluid"
                        src={require("../../../../Assets/Images/hand-drawn-flat-design-rpa-illustration.png")}
                      ></img>
                    </div>
                    <div className="text-container p-4">
                      Check how we approach enterprise software development and what we do to consistently deliver project success.</div>
                  </a>
                  <div className="loading-line"></div>
                </li>
                <li className=" border-right full-width-image-custom-soft mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <h5 className="mb-4 fw-bold">For Non-IT Managers</h5>
                    <div
                      className="d-flex col-md-12  justify-content-center serve-img"
                      style={{ height: "18rem" }}
                    >
                      <img
                        className="img-fluid"

                        src={require("../../../../Assets/Images/flat-design-benchmark-illustration.png")}
                      ></img>
                    </div>
                    <div className="text-container p-4">
                      Check how we can help you drive your business goals despite any time, budget, or technology constraints</div>
                  </a>
                  <div className="loading-line"></div>
                </li>
                <li className="full-width-image-custom-soft mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">
                    <h5 className="mb-4 fw-bold">For Startups</h5>

                    <div
                      className="d-flex col-md-12  justify-content-center serve-img"
                      style={{ height: "18rem" }}
                    >
                      <img
                        className="img-fluid"
                        src={require("../../../../Assets/Images/people-starting-business-project-illustration.png")}
                      ></img>
                    </div>

                    <div className="text-container p-4">
                      Check how we can take a product from idea to launch together and examples of successful software we helped introduce to the market.</div>

                  </a>
                  <div className="loading-line"></div>
                </li>


              </ul>
            </div>
          </div>

        </div>
      </div>


      {/* ------ We Offer----- */}
      <div className="service">
        <div className="toppart text-center">
          <h2 className="game-feature-heading1 mb-5">Services We Are Offering</h2>

        </div>
        <div className="box">
          <div className="row">
            <div className="col-12">

              <ul className="row d-flex justify-content-center p-0">
                <li className=" border-right full-width-image-service mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/consulting.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Software consulting</h5>
                    <p className="text-start px-1">
                      We offer expert software consultancy, delivering tailored solutions to optimize business processes, enhance efficiency, and drive growth through innovative technology
                    </p>
                  </a>

                </li>

                <li className=" border-right full-width-image-service mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/web-development-new.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Web Development</h5>
                    <p className="text-start px-1">
                      We provide professional web development services, creating responsive, high-performance websites tailored to your business needs, ensuring seamless user experiences and driving online growth.
                    </p>
                  </a>

                </li>
                <li className=" border-right full-width-image-service mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/mobile_development.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Mobile Development</h5>
                    <p className="text-start px-1">
                      We specialize in mobile app development, creating user-friendly, scalable apps for iOS and Android that improve customer experiences and accelerate business success
                    </p>
                  </a>

                </li>
                <li className=" border-right full-width-image-service mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/monitor.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Desktop Development</h5>
                    <p className="text-start px-1">
                      We specialize in desktop development, providing customized software solutions that streamline workflows, improve productivity, and elevate user experiences through cutting-edge technology tailored to your business needs.
                    </p>
                  </a>

                </li>
                <li className=" border-right full-width-image-service mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/sq-lite.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Database Development</h5>
                    <p className="text-start px-1">
                      We offer expert database development services, designing scalable and efficient databases that optimize data management, enhance performance, and ensure seamless integration, empowering your business with reliable and secure data solutions.
                    </p>
                  </a>

                </li>
                <li className=" border-right full-width-image-service mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/cloud.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Cloud Development</h5>
                    <p className="text-start px-1">
                      We provide cloud development services, crafting scalable and secure cloud solutions that improve flexibility, optimize resource usage, and drive innovation, enabling your business to leverage the full potential of cloud technology for growth and efficiency.
                    </p>
                  </a>

                </li>
                <li className=" border-right full-width-image-service mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/saas.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">SaaS Development</h5>
                    <p className="text-start px-1">
                      We specialize in mobile app development, creating user-friendly, scalable apps for iOS and Android that improve customer experiences and accelerate business success
                    </p>
                  </a>

                </li>
                <li className=" border-right full-width-image-service mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/miscellaneous.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">API Development and Software Integration</h5>
                    <p className="text-start px-1">
                      We provide expert API development and software integration services, enabling seamless system communication, enhancing efficiency, and delivering scalable, secure solutions that drive innovation and business growth.
                    </p>
                  </a>

                </li>
                <li className=" border-right full-width-image-service mobile-border-left mobile-border-right mobile-border-bottom">
                  <a className="industrylinks">

                    <div
                      className="d-flex col-md-12  justify-content-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        className=""
                        src={require("../../../../Assets/Images/upgrade.png")}
                      ></img>
                    </div>
                    <h5 className="my-3 fw-bold">Software Upgradation</h5>
                    <p className="text-start px-1">
                      We provide comprehensive software upgradation services, ensuring your systems stay up-to-date with the latest technologies, improving performance, security, and functionality while minimizing disruptions and maximizing business efficiency.
                    </p>
                  </a>

                </li>



              </ul>


            </div>
          </div>
        </div>
      </div>



      {/* ------------------ Selected Projects ----------------- */}

      <div>
        <h2>Some Of Our Developed Product</h2>
        <h3 className="text-center mt-5 mb-3">Paragon Hotel Management</h3>
        <div className="row">
          <div className="col-12 col-lg-6 h-100 d-flex align-items-center justidy-content-center">
            <div className="d-flex align-items-center justidy-content-center w-100 h-100">
              <Swiper
                spaceBetween={30}
                hashNavigation={{
                  watchState: true,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper"
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide data-hash="slide1">
                  <img
                    src={require("../../../../Assets/Images/S1/WhatsApp Image 2023-10-14 at 18.03.28_6856b413.jpg")}
                    style={{ width: "100%", height: "100%" }}
                  />
                </SwiperSlide>
                <SwiperSlide data-hash="slide2">
                  <img
                    src={require("../../../../Assets/Images/S1/p2.jpg")}
                    style={{ width: "100%", height: "100%" }}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="col-12 col-lg-6" style={{ maxHeight: "100%" }}>
            <div class="card">
              <div class="card-body">
                <h3 className="mt-0" style={{}}>
                  Project Information
                </h3>
                <ul class="list-group list-group-flush">
                  <li class=" ">
                    <strong>Category: </strong> Windows Form Application
                  </li>
                  <li class=" ">
                    <strong>Client: </strong> Paragon Hotel and Resort
                  </li>
                  <li class=" ">
                    <strong>Project Year: </strong> 2023
                  </li>
                </ul>
                <h3 className="mt-0" style={{ marginTop: "0 !important" }}>
                  Project Details
                </h3>
                <p>
                  Paragon Hotel Management, a Windows Forms app, streamlines
                  hotel operations. It handles reservations, check-ins, room
                  assignments, billing, staff scheduling, and reporting.
                  User-friendly and secure, it enhances guest experiences,
                  aids hoteliers in business optimization, and maintains data
                  accuracy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h3 className="text-center mt-5 mb-3">GO5050 Social Media</h3>
        <div className="row">
          <div
            className="col-12 col-lg-6 order-2 order-md-1 "
            style={{ maxHeight: "100%" }}
          >
            <div class="card">
              <div class="card-body">
                <h3 className="mt-0" style={{}}>
                  Project Information
                </h3>
                <ul class="list-group list-group-flush">
                  <li class=" ">
                    <strong>Category: </strong> GO5050 Social Media
                  </li>
                  <li class=" ">
                    <strong>Client: </strong> Leo Kameni
                  </li>
                  <li class=" ">
                    <strong>Project Year: </strong> 2022
                  </li>
                </ul>
                <h3 className="mt-0" style={{ marginTop: "0 !important" }}>
                  Project Details
                </h3>
                <p>
                  GO5050 SOCIALSHARE NETWORK is a vibrant social media
                  application, built on a robust technological foundation. It
                  utilizes Blazor WebAssembly for responsive web performance,
                  implements Redis cache management for efficient data
                  handling, and employs the Onion architecture for structured
                  code organization. The application offers users an engaging
                  platform for social sharing and interaction, enhancing the
                  overall user experience. With Entity Framework, it ensures
                  efficient data management and retrieval, making it a
                  comprehensive and dynamic social network.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-md-2 ">
            <div className="d-flex align-items-center justidy-content-center w-100 h-100">
              <Swiper
                spaceBetween={30}
                hashNavigation={{
                  watchState: true,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper"
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide data-hash="slide1">
                  <img
                    src={require("../../../../Assets/Images/S1/s2.png")}
                    style={{ width: "100%", height: "100%" }}
                  />
                </SwiperSlide>
                <SwiperSlide data-hash="slide2">
                  <img
                    src={require("../../../../Assets/Images/S1/s3.png")}
                    style={{ width: "100%", height: "100%" }}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------- SDLC --------------------------- */}

      <div className="sdlc">
        <div className="toppart text-center">
          <h2 className="game-feature-heading1 mb-5">Custom Software Lifecycle.</h2>

        </div>
        <div className="box">
          <div className="row">
            <div className="col-12">

              <ul className="row d-flex justify-content-center p-0">
                <li className="sdlc-names border-right full-width-image-sdlc mobile-border-left mobile-border-right mobile-border-bottom first-li d-flex flex-column align-items-center align-items-lg-start justify-content-center custom-first-li">
                  <div className="sdlc-container ms-lg-5">
                    <div className="timeline">
                      <div className="timeline-step">
                        <div className="timeline-icon">
                          <img
                            className=""
                            src={require("../../../../Assets/Images/selective.png")}
                          ></img>
                        </div>
                        <div className="timeline-content">
                          <div className="title">1. Planning</div>
                        </div>
                      </div>
                      <div className="timeline-step">
                        <div className="timeline-icon">
                          <img
                            className=""
                            src={require("../../../../Assets/Images/design.png")}
                          ></img>
                        </div>
                        <div className="timeline-content">
                          <div className="title">2. Designing</div>
                        </div>
                      </div>
                      <div className="timeline-step">
                        <div className="timeline-icon">   <img
                          className=""
                          src={require("../../../../Assets/Images/flow-chart.png")}
                        ></img></div>
                        <div className="timeline-content">
                          <div className="title">3. Defining</div>
                        </div>
                      </div>
                      <div className="timeline-step">
                        <div className="timeline-icon">
                          <img
                            className=""
                            src={require("../../../../Assets/Images/computer.png")}
                          ></img>
                        </div>
                        <div className="timeline-content">
                          <div className="title">4. Building</div>
                        </div>
                      </div>
                      <div className="timeline-step">
                        <div className="timeline-icon">
                          <img
                            className=""
                            src={require("../../../../Assets/Images/rpa.png")}
                          ></img>
                        </div>
                        <div className="timeline-content">
                          <div className="title">5. Testing</div>
                        </div>
                      </div>
                      <div className="timeline-step">
                        <div className="timeline-icon">
                          <img
                            className=""
                            src={require("../../../../Assets/Images/deployment.png")}
                          ></img>
                        </div>
                        <div className="timeline-content">
                          <div className="title">6. Deployment</div>
                        </div>
                      </div>
                      <div className="timeline-step">
                        <div className="timeline-icon">
                          <img
                            className=""
                            src={require("../../../../Assets/Images/technology.png")}
                          ></img>
                        </div>
                        <div className="timeline-content">
                          <div className="title">7. Maintenance</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="border-right full-width-image-sdlc mobile-border-left mobile-border-right mobile-border-bottom second-li d-flex  align-items-center justify-content-center">
                  <div className="d-flex col-md-12 justify-content-center my-4 sdlc-img" style={{ height: "27rem" }}>
                    <img
                      className="img-fluid"
                      src={require("../../../../Assets/Images/development-lifecycle.gif")}
                    ></img>
                  </div>
                </li>
              </ul>



            </div>
          </div>
        </div>
      </div>


      <Review />

      {/* --  How We Manage Custom Software Development Projects to Achieve Success ----   */}



      <div className="manage">
        <div className="toppart text-center">
          <h2 className="game-feature-heading1">How We Manage Custom Software Development Projects to Achieve Success</h2>
          <p className="text-center text-lg-start  my-4">At Taza Info Tech, we prioritize crafting software solutions that deliver measurable business impact. Our methodology is rooted in a detailed and thoughtful design process, combined with agile delivery and a clear, predictable project roadmap. With proven experience in delivering successful projects, we welcome you to review our practices and learn how we safeguard against common market traps, ensuring your project is handled with the highest standards of integrity and professionalism.</p>
        </div>
        <div className="box">
          <div className="row">
            <div className="col-12">
              <ul className="row d-flex justify-content-center p-0">

                <li className="full-width-image-manage mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12 justify-content-center my-4"
                    style={{ height: "3rem" }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../../Assets/Images/target.png")}
                    ></img>
                  </div>
                  <h5 className="fw-bold">Project Planing</h5>
                  <p className="p-3 text-start">Discover our software scope mapping and scope creep control practices. We also explain our approach to composing development teams and picking the best candidates.</p>

                </li>
                <li className="full-width-image-manage mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12 justify-content-center my-4"
                    style={{ height: "3rem" }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../../Assets/Images/estimate.png")}
                    ></img>
                  </div>
                  <h5 className="fw-bold">Cost Estamation</h5>
                  <p className="p-3 text-start">Learn the factors we consider when calculating development quotes and check our sample estimates. The page also describes our best practices to optimize project costs.</p>

                </li>
                <li className="full-width-image-manage mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12 justify-content-center my-4"
                    style={{ height: "3rem" }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../../Assets/Images/idea.png")}
                    ></img>
                  </div>
                  <h5 className="fw-bold">Collaboration</h5>
                  <p className="p-3 text-start">Get an idea of service-specific communication forms we rely on. The page contains a sample RACI-based collaboration model.</p>

                </li>
                <li className="full-width-image-manage mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12 justify-content-center my-4"
                    style={{ height: "3rem" }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../../Assets/Images/risk.png")}
                    ></img>
                  </div>
                  <h5 className="fw-bold">Risk Management</h5>
                  <p className="p-3 text-start">Understand major software development risk factors and explore our risk control and mitigation tactics at each SDLC stage.</p>

                </li>
                <li className="full-width-image-manage mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12 justify-content-center my-4"
                    style={{ height: "3rem" }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../../Assets/Images/change.png")}
                    ></img>
                  </div>
                  <h5 className="fw-bold">Change management</h5>
                  <p className="p-3 text-start">Explore our change request management process from submission to implementation and learn how we assess the feasibility of proposed changes.</p>

                </li>
                <li className="full-width-image-manage mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12 justify-content-center my-4"
                    style={{ height: "3rem" }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../../Assets/Images/quality-assurance.png")}
                    ></img>
                  </div>
                  <h5 className="fw-bold">Quality controls</h5>
                  <p className="p-3 text-start">Check the success measures we use to evaluate cooperation efficiency and see examples of KPI-based project reports we deliver throughout the SDLC.</p>

                </li>
                <li className="full-width-image-manage mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12 justify-content-center my-4"
                    style={{ height: "3rem" }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../../Assets/Images/knowledge.png")}
                    ></img>
                  </div>
                  <h5 className="fw-bold">Knowledge transfer</h5>
                  <p className="p-3 text-start">Discover our approach to knowledge creation and sharing and check sample software documents we deliver across various development stages.</p>

                </li>
                <li className="full-width-image-manage mobile-border-left mobile-border-right mobile-border-bottom">
                  <div
                    className="d-flex col-md-12 justify-content-center my-4"
                    style={{ height: "3rem" }}
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../../Assets/Images/insurance.png")}
                    ></img>
                  </div>
                  <h5 className="fw-bold">Post-launch warranty</h5>
                  <p className="p-3 text-start">Learn the typical coverage and duration of our after-launch software warranty. In the post-warranty period, we can take over the continuous management of your custom solution.</p>

                </li>



              </ul>
            </div>
          </div>

        </div>
      </div>


    </div>

  )

}


export default CustomSoftware;
