import React, { useEffect } from "react";
import BannerSection from "./BannerSection/BannerSection";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import Service from "./Services/Service";
import Product from "./Products/Product";
import OurOfferedSolutions from "./OurOfferedSolutions/OurOfferedSolutions";
import Technology from "./Technology/Technology";
import Review from "./Review/Review";
import Article from "./Article/Article";
import AskedQuestion from "./AskedQuestion/AskedQuestion";
import Location from "./Location/Location";
import Contact from "./Location/Contact";
import ChooseQuad from "./ChooseQuad/ChooseQuad";
import "./Home.css";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";
import TrustedBySection from "../TrustedBy/TrustedBy";
import IndustriesSection from "../IndustriesWorkWith/Industries";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-section-color">
      <BannerSection fromText={"Your Partner in Software Development"} 
         description={"At TZAZ INFOTECH, we are committed to being your trusted partner in software development. Our team of experts delivers tailored software solutions designed to meet your unique business needs. From initial concept to final deployment, we provide end-to-end software development services that drive innovation and efficiency. Whether you need custom software, web development, cybersecurity solutions, or cloud-based solutions, we ensure that each project is executed with precision and a focus on quality."}
         description2= {"Let us help you create cutting-edge solutions that propel your business forward in today’s competitive market."}
       />
      {/* <TrustedBySection/> */}
      <WhoWeAre />
      <Service />
      <OurOfferedSolutions/>
      <Product />
      <Technology />
      <IndustriesSection/>
      <Review />
      {/* <Article /> */}
      <AskedQuestion />
    </div>
  );
};

export default Home;
