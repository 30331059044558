const inetialValue=[
    {
        name:"Coding best practices",
        points:[
            "Adherence to .NET coding guidelines, use of style checking tools.",
            "Adherence to data security practices.",
            "Descriptive names for all variables and in-code comments.",
            "Code documentation.",
            "Code split into short and focused units.",
            "Use of frameworks’ APIs, third-party libraries, version control tools.",
            "Ensured code portability.",
            "Reasonable standardization and automation."
            
        ]
    },
    {
        name:"Unit testing",
        points:[
            "Unit tests are created as part of a functional requirements specification.",
            "Tests verify code behavior in response to the input of standard, boundary, and incorrect values.",
            "Tests are rerun after each significant code change to guarantee proper performance.",
        ]
    },
    {
        name:"Code review practices",
        points:[
            "Ad hoc review.",
            "Peer review.",
            "Code walkthrough.",
            "Code inspection.",
        ]
    },
    {
        name:"Code quality metrics",
        points:[
            "Maintainability index (MI).",
            "Cyclomatic Complexity (CC).",
            "Depth of Inheritance.",
            "Class Coupling.",
        ]
    },
]

const getEnsuringCodeQuality=()=>{
    return inetialValue
}

export default getEnsuringCodeQuality