import React from 'react';
import "./style.css"
import getEnsuringCodeQuality from '../EnsuringQualityOfCode/DemoData/EnsuringCodeDemoData';
import getSuccessDelivaryData from './DemoData/SuccessFullDeliveryDemoData';
const SuccessFullDelivery = () => {
    return (
        <div className='centerContainer container'>
            <h1 className="tittleText">Our Approach to Ensuring the Delivery of a Successful Project Development</h1>
            <div className='successContainer'>
                {
                    getSuccessDelivaryData()?.map((item,idx)=>{
                        return(
                            <div key={idx} className='successSingleCard'>
                                <div>{item.icon}</div>
                                <h1>{item.tittle}</h1>
                                <p>{item.description}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default SuccessFullDelivery;