import React, { useEffect, useState } from "react";
import "./AllServices.css";
import NavBar from "../HomePage/NavBar/NavBar";
import BannerSection from "../HomePage/BannerSection/BannerSection";
import AllServiceList from "./AllServiceList/AllServiceList";
import axios from "axios";
import Footer from "../HomePage/Footer/Footer";
import Technology from "../HomePage/Technology/Technology";
import IndustriesSection from "../IndustriesWorkWith/Industries";
import Product from "../HomePage/Products/Product";
function AllServices(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BannerSection
        from={"service"}
        fromText={"Your Partner in Software Development" }
        description={"At TZAZ INFOTECH, we are committed to being your trusted partner in software development. Our team of experts delivers tailored software solutions designed to meet your unique business needs. From initial concept to final deployment, we provide end-to-end software development services that drive innovation and efficiency. Whether you need custom software, web development, cybersecurity solutions, or cloud-based solutions, we ensure that each project is executed with precision and a focus on quality."}
        description2= {"Let us help you create cutting-edge solutions that propel your business forward in today’s competitive market."}
      
      />
      <AllServiceList />
      <Technology />
      <IndustriesSection/>
      <Product />
    </div>
  );
}

export default AllServices;
