import React, { useEffect } from "react";
import CareerBanner from "./BannerSection/CareerBanner";
import Benefit from "./Benefit/Benefit";
import HowtoJoin from "./HowToJoin/HowtoJoin";
import Resume from "./Resume/Resume";
import OpenPosition from "./OpenPosition/OpenPosition";
import NavBar from "../HomePage/NavBar/NavBar";
import Footer from "../HomePage/Footer/Footer";
import BannerSection from "../HomePage/BannerSection/BannerSection";

function Career() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <CareerBanner /> */}
      <BannerSection
      
        description={
          " At TZAZ INFOTECH, we believe in empowering our team to reach their full potential. We offer a rich array of benefits and opportunities for professional and personal growth."
        }
        fromText={"Career"}
      />
      <Benefit />
      {/* <HowtoJoin /> */}
      <OpenPosition />
      {/* <Resume /> */}
    </div>
  );
}

export default Career;
