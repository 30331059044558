import React from "react";
import { getDevelopersDetails } from "../../../Demodata";
import "./style.css";
import getlanguageAndIde from "./DemoData";
const LanguageAndIde = () => {
  const data=getlanguageAndIde()
  return (
    <div className="centerContainer container">
       <h1 className="tittleText">Programming languages and IDEs</h1>
      <div className="languageContainer">
        <div>
        <h3 className="containerTittle" >Web Technology</h3>
        <div className="imagesContainer">
          {data.web?.map((item,idx)=>{
            return(<div className="imgContainer"><img key={idx} alt="skillImage" src={item}></img></div>)
          })}
        </div>
        </div>

        <div>
        <h3 className="containerTittle" >Ides</h3>
        <div className="imagesContainer">
          {data.Ides?.map((item,idx)=>{
            return(<div className="imgContainer"><img key={idx} alt="skillImage" src={item}></img></div>)
          })}
        </div>
        </div>

        <div>
        <h3 className="containerTittle" >Mobile Technology</h3>
        <div className="imagesContainer">
          {data.mobile?.map((item,idx)=>{
            return(<div className="imgContainer"><img key={idx} alt="skillImage" src={item}></img></div>)
          })}
        </div>
        </div>

        <div>
        <h3 className="containerTittle" >UI/UX Design</h3>
        <div className="imagesContainer">
          {data.ui?.map((item,idx)=>{
            return(<div className="imgContainer"><img key={idx} alt="skillImage" src={item}></img></div>)
          })}
        </div>
        </div>

        <div>
        <h3 className="containerTittle" >Cloud</h3>
        <div className="imagesContainer">
          {data.clowd?.map((item,idx)=>{
            return(<div className="imgContainer"><img key={idx} alt="skillImage" src={item}></img></div>)
          })}
        </div>
        </div>

        <div>
        <h3 className="containerTittle" >Database</h3>
        <div className="imagesContainer">
          {data.database?.map((item,idx)=>{
            return(<div className="imgContainer"><img key={idx} alt="skillImage" src={item}></img></div>)
          })}
        </div>
        </div>


      </div>
    </div>
  );
};

export default LanguageAndIde;
