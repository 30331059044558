import React, { useEffect, useState } from "react";
import axios from "axios";
import bannerImage from "./../../Assets/Images/CareerBanner.png";
import fb from "./../../Assets/Icons/facebook.png";
import twitter from "./../../Assets/Icons/twitter.png";
import linkedin from "./../../Assets/Icons/linkedin.png";
function Employee() {
  const [getData, setData] = useState([]);
  useEffect(() => {
    axios
      .get("https://testwebapiqttzaz.azurewebsites.net/api/Employee/getAllEmployee")
      .then((response) => {
        setData(response.data);
      });
  }, []);
  return (
    <div>
      <div>
        <div>
          <div className="container-fluid sectionMergin">
            <div className="row d-flex align-items-center ">
              <div className="col-md-5 col-12">
                <div>
                  <div className="text-md-end text-center text-uppercase ">
                    <h1>Team</h1>
                  </div>

                  <div className="text-uppercase">
                    <p className=" text-md-end text-center">Meet our team</p>
                  </div>
                </div>
              </div>
              <div className="col-md-7 d-flex justify-content-md-start justify-content-center  ">
                <div className="bannerImage">
                  <img src={bannerImage} alt=".." className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="sectionMergin">
            <div className="container-fluid sectionContainer">
              <div className="row d-flex justify-content-center gap-md-4">
                {getData.map((data) => {
                  return (
                    <div
                      className=" col-md-4 col-12  themeColour rounded-4 p-2 ps-3 mb-3 "
                      key={data.name}
                    >
                      <h6
                        className=" fs-4 fw-normal text-uppercase"
                        style={{ wordSpacing: ".5rem" }}
                      >
                        {data.name}
                      </h6>
                      <p className=" fs-6 fw-light fst-italic">
                        {data.position}
                      </p>
                      <a href={data.fbLink} className="">
                        <div className="socialIconBox">
                          <img src={fb} className=" socialIcon"></img>
                        </div>
                      </a>
                      <a href={data.twitterLink} className="">
                        <div className="socialIconBox">
                          <img src={twitter} className=" socialIcon"></img>
                        </div>
                      </a>
                      <a href={data.linkedInLink} className="">
                        <div className="socialIconBox">
                          <img src={linkedin} className=" socialIcon"></img>
                        </div>
                      </a>
                      <div className=" d-flex justify-content-end">
                        <div className=" employeeImageBox d-flex justify-content-center">
                          <img
                            src={data.image}
                            className=" img-fluid employeeImage"
                          ></img>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Employee;
