/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Product.css";
import goImg from "./../../../Assets/Images/Go5050.png";
import gotasteImg from "./../../../Assets/Images/Gotaste Logo-13.png";
import pantomimeImg from "./../../../Assets/Images/sproutLogo.png";
import paragonImg from "./../../../Assets/Images/image001.png";
import smImg from "./../../../Assets/Images/SMKW_logo3.png";
import rhtiImg from "./../../../Assets/Images/team2.png";
import rediImg from "./../../../Assets/Images/team3.png";
import silverspooniImg from "./../../../Assets/Images/team4.png";
import nestiImg from "./../../../Assets/Images/team6.png";
import quentediImg from "./../../../Assets/Images/team7.png";
import infiniiImg from "./../../../Assets/Images/logo (1).png";

import Aos from "aos";
import "aos/dist/aos.css";

const Product = () => {
  const [userData, setData] = useState([
    {
      id: "",
      projectName: "",
      projectHeader: "",
      projectDescription: "",
      projectLink: "",
      image: "",
    },
    {
      id: "",
      projectName: "",
      projectHeader: "",
      projectDescription: "",
      projectLink: "",
      image: "",
    },
    {
      id: "",
      projectName: "",
      projectHeader: "",
      projectDescription: "",
      projectLink: "",
      image: "",
    },
    {
      id: "",
      projectName: "",
      projectHeader: "",
      projectDescription: "",
      projectLink: "",
      image: "",
    },
  ]);
  // useEffect(() => {
  //   axios
  //     .get("https://testwebapiqttzaz.azurewebsites.net/api/Project/getProjects")
  //     .then((response) => {
  //       setData(response.data);
  //     });
  //   Aos.init()
  // }, []);
  return (
    // <div className="">
    //   <div className="container-fluid d-flex justify-content-center align-items-center productSectionColor   pb-5">
    //     <div className=" sectionContainer mt-5">
    //       <div className="row">
    //         <div className="col-md-12 d-flex justify-content-center ">
    //           <h2 className="text-center fw-bold fs-1 mb-3 ">
    //             Some Of Our Developed Product
    //           </h2>
    //         </div>
    //       </div>
    //       <div className="row">
    //         <div className="d-flex justify-content-center">
    //           <div className="col-md-10">
    //             <div className="d-flex justify-content-center pb-4">
    //               <h6 className=" fw-semibold text-center ">
    //                 Our company specializes in software outsourcing and provides
    //                 robust, scalable, and efficient solutions to clients around
    //                 the world. We are committed to providing quality services at
    //                 competitive prices. Our goal is to help our clients achieve
    //                 their business objectives by providing them with the best
    //                 possible software solutions.
    //               </h6>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       {/* <div className="row">
    //         <div className="col-md-6">
    //           <div className="row">
    //             <div className="col-md-12">
    //               <div className="">
    //                 <div className="card projectCard themeColour">
    //                   <img
    //                     src={userData[0].image}
    //                     // src={require(`./../../../Assets/Images/go5050logo2 1.png`)}
    //                     alt="Product"
    //                     className="card-img-top"
    //                   />
    //                   <div className="card-body">
    //                     <h3 className="card-title">
    //                       {userData[0].projectName}
    //                     </h3>
    //                     <p className="card-text">
    //                       {" "}
    //                       {userData[0].projectHeader}{" "}
    //                     </p>
    //                     <a href={userData[0].projectLink} className=" readMore">
    //                       Explore More
    //                     </a>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-md-12">
    //               <div className="">
    //                 <div className="card projectCard themeColour">
    //                   <img
    //                     // src={require(`./../../../Assets/Images/${userData[1].image}`)}
    //                     // src={require(`./../../../Assets/Images/Go5050.png`)}
    //                     // src={require(userData[1].image)}
    //                     src={userData[1].image}
    //                     alt="Product"
    //                     className="card-img-top"
    //                     style={{ height: "350px" }}
    //                   />
    //                   <div className="card-body">
    //                     <h3 className="card-title">
    //                       {userData[1].projectName}
    //                     </h3>
    //                     <p className="card-text">{userData[1].projectHeader}</p>
    //                     <a href={userData[1].projectLink} className=" readMore">
    //                       Explore More
    //                     </a>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-md-6">
    //           <div className="row">
    //             <div className="col-md-12">
    //               <div className="">
    //                 <div className="card projectCard themeColour">
    //                   <img
    //                     //src={require(userData[2].image)}
    //                     src={userData[2].image}
    //                     alt="Product"
    //                     className="card-img-top"
    //                     style={{ height: "350px" }}
    //                   />
    //                   <div className="card-body">
    //                     <h3 className="card-title">
    //                       {userData[2].projectName}{" "}
    //                     </h3>
    //                     <p className="card-text">
    //                       {" "}
    //                       {userData[2].projectHeader}{" "}
    //                     </p>
    //                     <a href={userData[2].projectLink} className=" readMore">
    //                       Explore More
    //                     </a>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-md-12">
    //               <div className="">
    //                 <div className="card projectCard themeColour">
    //                   <img
    //                     //src={require(userData[3].image)}
    //                     src={userData[3].image}
    //                     alt="Product"
    //                     className="card-img-top"
    //                   />
    //                   <div className="card-body">
    //                     <h3 className="card-title">
    //                       {userData[3].projectName}
    //                     </h3>
    //                     <p className="card-text">{userData[3].projectHeader}</p>
    //                     <a href={userData[3].projectLink} className=" readMore">
    //                       Explore More
    //                     </a>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div> */}
    //       <div className="row " >
    //         <div className="col-12 col-md-4 h-100  customProduct p-3 mt-md-5" >
    //           <div class="card border-0 h-100 Custom-card-design" data-aos="fade-up"
    //           >
    //             <div className='px-4 pt-4 landingPageImage' >
    //               <img src={goImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>GO5050</h4>
    //               <p class="card-text">THE SOCIALSHARE NETWORK FOR SAFE SHARING AND SAVING! TRY FREE FOR A MONTH
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://go5050.co.uk" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>
    //           {/* <div className="card-custom-product card h-100  p-3 mt-md-5">
    //             <div className="card-body">
    //               <img src={goImg} alt="" className="img-fluid img-product" />
    //               <h4>GO5050</h4>
    //               <p>THE SOCIALSHARE NETWORK FOR SAFE SHARING AND SAVING! TRY FREE FOR A MONTH</p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://go5050.co.uk/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className="p-2">

    //             </div>
    //           </div> */}

    //         </div>
    //         <div className="col-12 col-md-4  h-100 customProduct  p-3 mt-md-5" data-aos="fade-up"
    //         >
    //           <div class="card border-0 h-100 Custom-card-design" >
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={gotasteImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>Go Taste</h4>
    //               <p class="card-text">THE SOCIALSHARE NETWORK FOR SAFE SHARING AND SAVING! TRY FREE FOR A MONTH.
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://gotaste.uk/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>
    //           {/* <div className="card-custom-product  p-3 mt-md-5">
    //             <img src={gotasteImg} alt="" className="img-fluid img-product" />
    //             <h4>Go Taste</h4>
    //             <p>THE SOCIALSHARE NETWORK FOR Food. Any one can order food from this website.  </p>
    //             <div className="d-flex justify-content-end">
    //               <a href="https://gotaste.uk/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //             </div>

    //           </div> */}
    //         </div>
    //         <div className="col-12 col-md-4  h-100  customProduct p-3 mt-md-5" data-aos="fade-up"
    //         >
    //           <div class="card border-0 h-100 Custom-card-design" >
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={pantomimeImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>Pantomime</h4>
    //               <p class="card-text">They provide online interactive, world-class education for ANYONE, ANYWHERE who are physically impaired.
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="http://web.pantomimeltd.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>

    //         </div>
    //         <div className="col-12 col-md-4  h-100  customProduct" data-aos="fade-up"
    //         >
    //           {/* <div className="card-custom-product card h-100  p-3 mt-md-5">
    //             <div className="card-body">
    //               <img src={paragonImg} alt="" className="img-fluid img-product" />
    //               <h4>Paragon Hotel Management System</h4>
    //               <p>THE SOCIALSHARE NETWORK FOR SAFE SHARING AND SAVING! TRY FREE FOR A MONTH</p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="http://web.pantomimeltd.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className="p-2">

    //             </div>
    //           </div> */}

    //           <div class="card border-0 h-100 Custom-card-design p-3 mt-md-5">
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={paragonImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>Paragon Hotel Management System</h4>
    //               <p class="card-text">THE SOCIALSHARE NETWORK FOR SAFE SHARING AND SAVING! TRY FREE FOR A MONTH.
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://paragonresortbd.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>
    //         </div>

    //         <div className="col-12 col-md-4  h-100  customProduct" data-aos="fade-up"
    //         >
    //           <div class="card border-0 h-100 Custom-card-design p-3 mt-md-5">
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={smImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>SM knitwear</h4>
    //               <p class="card-text">
    //                 A leading company specializing in the design, manufacturing, and distribution of high-quality garments for diverse tastes.
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="http://smknitwear.com/NewWebsite/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>

    //         </div>

    //         <div className="col-12 col-md-4  h-100  customProduct" data-aos="fade-up"
    //         >
    //           <div class="card border-0 h-100 Custom-card-design p-3 mt-md-5">
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={rhtiImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>The Regency Hospitality Training Institute</h4>
    //               <p class="card-text">
    //                 A world-class training institute for hospitality services.
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://rhtibd.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>

    //         </div>

    //         <div className="col-12 col-md-4  h-100  customProduct" >
    //           <div class="card border-0 h-100 Custom-card-design p-3 mt-md-5" data-aos="fade-up"
    //           >
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={rediImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>Red Origin</h4>
    //               <p class="card-text">
    //                 A leading clothing brand in United Kingdom
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://rhtibd.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>

    //         </div>
    //         <div className="col-12 col-md-4  h-100  customProduct" data-aos="fade-up"
    //         >
    //           <div class="card border-0 h-100 Custom-card-design p-3 mt-md-5">
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={silverspooniImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>Silver Spoon</h4>
    //               <p class="card-text">
    //                 A Multi Cuisine Fine Dining Restaurant in Chittagong
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://www.facebook.com/SSpoonBD/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>

    //         </div>
    //         <div className="col-12 col-md-4  h-100  customProduct" data-aos="fade-up"
    //         >
    //           <div class="card border-0 h-100 Custom-card-design p-3 mt-md-5">
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={nestiImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>NESTCARE SOLUTIONS</h4>
    //               <p class="card-text">
    //                 They are San Bernardino, California based company. They provide various services.
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://nestcaresolutions.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>

    //         </div>

    //         <div className="col-12 col-md-4  h-100  customProduct" data-aos="fade-up"
    //         >
    //           <div class="card border-0 h-100 Custom-card-design p-3 mt-md-5">
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={quentediImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>Quintet Alliance Pvt. Ltd</h4>
    //               <p class="card-text">
    //                 They provide various services IT Solutions and IT CONSULTANCY.
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://quintetalliance.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>

    //         </div>

    //         <div className="col-12 col-md-4  h-100  customProduct" data-aos="fade-up"
    //         >
    //           <div class="card border-0 h-100 Custom-card-design p-3 mt-md-5">
    //             <div className='px-4 pt-4 landingPageImage'>
    //               <img src={infiniiImg} class="card-img-top rounded-xl" alt="..." />
    //             </div>

    //             <div class="card-body px-4 pt-4">
    //               <h4>Infinigent Consulting</h4>
    //               <p class="card-text">
    //                 infinigent consulting Ltd. is a multinational strategic management consulting company.
    //               </p>
    //               <div className="d-flex justify-content-end">
    //                 <a href="https://www.infinigent.co" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#3498DB" }}><b>Visit</b></a>
    //               </div>
    //             </div>
    //             <div className='carf-footer px-4 pt-4'>

    //             </div>
    //           </div>

    //         </div>

    //       </div>

    //     </div>
    //   </div>
    // </div>
    // not looking good
    //   <div className="brand-fluid container text-center">
    //   <h2 className="text-center">Empowering Through Partnership</h2>
    //   <p className="text-center">Our Valuable Clients</p>
    //   <div className="row border-bottom">
    //     <div className="col-xs-6 col-md-3 mb-3 text-center border-right">
    //       <div className="hover-display">
    //         <img src="https://www.techgropse.com/common/images/commonpart/calm.png" alt="" />
    //         <img src="https://www.techgropse.com/common/images/commonpart/calm-color.png" alt="" />
    //       </div>
    //     </div>
    //     <div className="col-xs-6 col-md-3 mb-3 text-center border-right">
    //       <div className="hover-display">
    //         <img src="https://www.techgropse.com/common/images/commonpart/jahez.png" alt="" />
    //         <img src="https://www.techgropse.com/common/images/commonpart/jahez-color.png" alt="" />
    //       </div>
    //     </div>
    //     <div className="col-xs-6 col-md-3 mb-3 text-center border-right">
    //       <div className="hover-display">
    //         <img src="https://www.techgropse.com/common/images/commonpart/air.png" alt="" />
    //         <img src="https://www.techgropse.com/common/images/commonpart/air-color.png" alt="" />
    //       </div>
    //     </div>
    //     <div className="col-xs-6 col-md-3 mb-3 text-center">
    //       <div className="hover-display">
    //         <img src="https://www.techgropse.com/common/images/commonpart/zanomy.png" alt="" />
    //         <img src="https://www.techgropse.com/common/images/commonpart/zanomy-color.png" alt="" />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="row brand_flex">
    //     <div className="col-xs-6 col-md-3 mb-3 text-center border-right">
    //       <div className="hover-display">
    //         <img src="https://www.techgropse.com/common/images/commonpart/namshi.png" alt="" />
    //         <img src="https://www.techgropse.com/common/images/commonpart/namshi.png" alt="" />
    //       </div>
    //     </div>
    //     <div className="col-xs-6 col-md-3 mb-3 text-center border-right">
    //       <div className="hover-display">
    //         <img src="https://www.techgropse.com/common/images/commonpart/matic.png" alt="" />
    //         <img src="https://www.techgropse.com/common/images/commonpart/matic-color.png" alt="" />
    //       </div>
    //     </div>
    //     <div className="col-xs-6 col-md-3 mb-3 text-center">
    //       <div className="hover-display">
    //         <img src="https://www.techgropse.com/common/images/commonpart/carasti.png" alt="" />
    //         <img src="https://www.techgropse.com/common/images/commonpart/carasti-color.png" alt="" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="container-fluid industriesp">
      <div className="container">
        <div className="brand-fluid container text-center">
          <h2 className="text-center">Empowering Through Partnership</h2>
          <p className="text-center">Our Valuable Clients</p>
          {/* <div className="main-in">
            <div className="box">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                   
                  </div>
                 
                </div>
              </div>
            </div>
          </div> */}
          <div className="row d-flex justify-content-center">
            {/* <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
                href="https://go5050.co.uk/"
                style={{ height: "58%", width: "66%" }}
              >
                <img
                  src={require("./../../../Assets/Images/Go5050.png")}
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
                href="https://gotaste.uk/"
                style={{ height: "56%", width: "65%" }}
              >
                <img
                  src={require("./../../../Assets/Images/Gotaste Logo-13.png")}
                  className="img-fluid"
                />
              </a>
            </div> */}
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
               
                style={{ height: "28%", width: "55%" }}
              >
                <img
                  src={require("./../../../Assets/Images/logo (1).png")}
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
                
                style={{ height: "66%", width: "72%" }}
              >
                <img
                  src={require("./../../../Assets/Images/image001.png")}
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
               
                style={{ height: "42%", width: "65%" }}
              >
                <img
                  src={require("./../../../Assets/Images/team6.png")}
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
                
                style={{ height: "80%", width: "95%" }}
              >
                <img
                  src={require("./../../../Assets/Images/SproutLogo_big-removebg.png")}
                  className="img-fluid"
                  // style={{objectFit:"contain"}}
                />
              </a>
            </div>
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
                
                style={{ height: "82%", width: "82%" }}
              >
                <img
                  src={require("./../../../Assets/Images/team7.png")}
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
               
                style={{ height: "62%", width: "70%" }}
              >
                <img
                  src={require("./../../../Assets/Images/SMKW_logo3.png")}
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
               
                style={{ height: "70%", width: "68%" }}
              >
                <img
                  src={require("./../../../Assets/Images/rhti-logo.png")}
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
                
                style={{ height: "45%", width: "45%" }}
              >
                <img
                  src={require("./../../../Assets/Images/team4.png")}
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
             
                style={{ height: "45%", width: "45%" }}
              >
                <img
                  src={require("./../../../Assets/Images/team3.png")}
                  className="img-fluid"
                />
              </a>
            </div>
            {/* <div className="col-6 col-md-3 product-col">
              <a
                className="industrylinks full-width-image-industriesp"
                href="https://web.modinatraders.com/"
                style={{ height: "62%", width: "70%" }}
              >
                <img
                  src={require("./../../../Assets/Images/ModinaLogo.png")}
                  className="img-fluid"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
