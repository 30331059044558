import React from 'react';
import "./style.css"
import getCorporationData from './Demodata/CorporationDemoData';
import { HiArrowLongRight } from "react-icons/hi2";
const CorporationModel = () => {
    return (
        <div className='centerContainer container'>
             <h1 className="tittleText">Cooperation model</h1>
            <div className='corporationModelcontainer'>
            { 
                getCorporationData()?.map((item,idx)=>{
                    return(
                        <div className='corporationCart'>
                            <img src={item.image} alt='corporationCartImage00'/>
                            <h1>{item.tittle}</h1>
                            <p>{item.description}</p>
                            <button>{item.buttonName} <span><HiArrowLongRight /></span></button>
                        </div>
                    )
                })
             }
            </div>
        </div>
    );
};

export default CorporationModel;