import { MdGroups } from "react-icons/md";
import { HiDocumentText } from "react-icons/hi2";
import { TbRulerMeasure } from "react-icons/tb";
import { RiDirectionLine } from "react-icons/ri";
import { GrCertificate } from "react-icons/gr";
import { MdOutlineSecurity } from "react-icons/md";
import { FaTriangleExclamation } from "react-icons/fa6";
import { MdMenuBook } from "react-icons/md";
import { IoDocumentsSharp } from "react-icons/io5";
const demoData=[
    {
        tittle:"Resource allocation",
        description:"Discover strategies for assigning the ideal candidates to your project's roles, whether you're augmenting your team or outsourcing the entire project.",
        icon:<MdGroups />
    },
    {
        tittle:"Collaboration techniques",
        description:"Learn strategies for organizing effective communication and gauging the success of collaborative efforts.",
        icon:<MdGroups />
    },
    {
        tittle:"Project reporting",
        description:"Discover the range and examples of reports (weekly, bi-weekly, or monthly) on different project dimensions available to you.",
        icon:<HiDocumentText />
    },
    {
        tittle:"Success metrics",
        description:"Examine the examples of key performance indicators and check how they can be woven into Agile methodologies to measure project success effectively.",
        icon:<TbRulerMeasure />
    },
    {
        tittle:"Adapting to change",
        description:"Learn to seamlessly integrate change management into your software practices, ensuring fluid communication without red tape.",
        icon:<RiDirectionLine />
    },
    {
        tittle:"Quality management",
        description:"Delve into essential quality KPIs and activities designated for each stage of the SDLC.",
        icon:<GrCertificate />
    },
    {
        tittle:"Securing you project",
        description:"Grasp the core principles of project security, including planning strategies and identifying key security team roles.",
        icon:<MdOutlineSecurity />
    },
    {
        tittle:"Risk management",
        description:"Understand how to effectively monitor and mitigate risks before and as they occur.",
        icon:<FaTriangleExclamation />
    },
    {
        tittle:"Optimizing knowledge management",
        description:"Discover the best practices for accumulating, managing, and sharing knowledge within your team, as well as effectively distributing knowledge management responsibilities.",
        icon:<MdMenuBook />
    },
    {
        tittle:"Project documentation",
        description:"Explore the vital documentation required at each SDLC stage and access samples of these documents.",
        icon:<IoDocumentsSharp />
    },
]

const getSuccessDelivaryData=()=>{
    return demoData
}

export default getSuccessDelivaryData