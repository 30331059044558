import augment from  "./augment.png"
import add from "./add.png"
const data=[
    {
        tittle:".NET team augmentation",
        description:"Rely on the experience of ScienceSoft’s .NET developer(s) to get over skill deficiency or bridge staff gaps in your project.",
        buttonName:"Go for team augmentation",
        image:augment
    },
    {
        tittle:"Dedicated .NET team",
        description:"Hire a team of skilled .NET talents, managed by ScienceSoft’s Team Lead/PM, to deliver your .NET project or its part.",
        buttonName:"Go for a dedicated team",
        image:add
    },
]

const getCorporationData=()=>{
    return data
}

export default getCorporationData