import React from "react";
import "./style.css";
const HowWeHire = () => {
  return (
    <div className="hireCard centerContainer container">
      <h1 className="tittleText">Hiring Procedure</h1>

      <div className="stepsParentContainer">
        <div className="stepsContainer">
          <div className="singleHireCart_top">
            1<h1>Careful CV scanning.</h1>
          </div>
          <div className="middleBorder"></div>
          <div className="singleHireCart">
            2<h1>HR specialist's interview.</h1>
          </div>
          <div className="middleBorder"></div>
          <div className="singleHireCart_top">
            3<h1>Test task 1 (soft skills).</h1>
          </div>
          <div className="middleBorder"></div>
          <div className="singleHireCart">
            4<h1>PM's/Team Lead's interview.</h1>
          </div>
          <div className="middleBorder"></div>
          <div className="singleHireCart_top">
            5<h1>Test task 2 (hard skills).</h1>
          </div>
          <div className="middleBorder"></div>
          <div className="singleHireCart">
            6<h1>CTO's interview.</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeHire;
