import React, { useEffect } from "react";
import "./BannerSection.css";
import TypewriterComponent from "typewriter-effect";
import Aos from "aos";
import "aos/dist/aos.css";

import headerImage from "./../../../Assets/Images/Banner.png";
const BannerSection = (props) => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <>
      <div className="bannerHeight">
        <div className="container  bg-text-color">
          <div className="row d-flex align-items-center bannerRow">
            <div className=" col-lg-9 col-12 bannerText">
              <div
                className="d-flex headerText justify-content-center align-item-center align-items-lg-start"
                data-aos="fade-right"
                style={{}}
              >
                {/* <TypewriterComponent
              options={{
                strings: ["Navigating Tomorrow's Tech, Today", 'Empowering Possibilities, One Byte at a Time', 'Innovate, Integrate, Inspire', 'Where Code Meets Creativity'],
                autoStart: true,
                loop: true,
              }}
            /> */}
                <header>
                  <h1 className="text-lg-start text-center taglineText ">
                    {props.fromText}
                  </h1>
                </header>
                <div className="mt-4 text-lg-start text-center ">
                  <p className="bannnerParagraph">{props.description}</p>
                  <p className="bannnerParagraph">{props.description2}</p>
                </div>

                {/* <h1 className=" ">
              Empowering Possibilities, One Byte at a Time
            </h1> */}
                {/* <h3 className="text-md-end text-center fw-light">
              Comes with All Essential Module and Elements
            </h3> */}
                {/* <br></br> */}
                <div className="btnContainer">
                  {props.TwoButton ? (
                    <div className="d-flex  gap-2">
                      <a style={{textDecoration:"none"}} href="#Contactform" className="contactUsButtonDefault">
                        Hire developers
                      </a>
                      {/* <button className="contactUsButtonDefault">
                      Get a cost estimate
                    </button> */}
                    </div>
                  ) : (
                    <a style={{textDecoration:"none"}} href={props.fromText==="Career"&&"#OpenJobs"}   
                      className="contactUsButtonDefault"
                      // onClick={() => {
                      //   const element = document.getElementById("location");
                      //   element.scrollIntoView();
                      // }}
                    >
                      {props.fromText === "Career" ? "Apply Now" : "Contact Us"}
                    </a>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-md-6  col-12 d-flex justify-content-center " data-aos="fade-left">
          <div className="img-fluid">
            <img
              src={headerImage}
              alt="HomeBannerImage"
              className="img-fluid"
            />
          </div>
        </div> */}
          </div>
          {/* <div className="row d-flex justify-content-center positionAbsolute">
          <div className="col-12 container-fluid boxcontainer ">
            <div className="row ">
              <div className="col-4 border-end border-2 border-light">
                <h3 className=" text-center">100+</h3>
                <p className="text-center">Happy Client</p>
              </div>
              <div className="col-4 border-end border-2 border-light">
                <h3 className="text-center">11+</h3>
                <p className="text-center">Projects</p>
              </div>
              <div className="col-4">
                <h3 className="text-center">5+</h3>
                <p className="text-center">Years</p>
              </div>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default BannerSection;
// boxcontainer
// d-flex flex-nowrap
