import React from "react";
import "./Pages/AllServices/ServiceDetailsView/ServiceDetailsView";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Test(props) {
  return (
    <div className="serviceDetailsPage">
      <div class="container">
        <h1 class="mt-4">
          Transforming Visions into Reality with TZAZ INFOTECH
        </h1>

        <div>
          <h2>Unveiling TZAZ INFOTECH: A Legacy of Excellence</h2>
          <p>
          TZAZ INFOTECH, with its headquarters nestled at the heart of
            innovation, has emerged as a trailblazer in the sphere of
            infrastructure and architecture development. Founded on the
            principles of creativity, precision, and unwavering commitment, the
            company has etched its name in the annals of groundbreaking
            projects. Led by a team of visionaries and seasoned professionals,
            TZAZ INFOTECH prides itself on blending traditional wisdom with
            contemporary innovation, resulting in awe-inspiring structures that
            stand as a testament to their prowess.
          </p>
        </div>

        <div>
          <h2>The TZAZ INFOTECH Approach: Where Vision Meets Reality</h2>
          <p>
            At the heart of TZAZ INFOTECH's success lies their unique TZAZ INFOTECH
            Approach, a holistic method meticulously crafted to bridge the gap
            between visions and reality. This four-pronged strategy encompasses
            meticulous planning, innovative design, sustainable practices, and
            seamless execution. By integrating these elements seamlessly, TZAZ INFOTECH ensures that every project undertaken is not just a
            structure but a living, breathing entity that harmonizes with its
            surroundings.
          </p>
        </div>

        <div>
          <h2>Innovative Designs Redefining Landscapes</h2>
          <p>
            In the realm of architecture, innovation is the driving force behind
            iconic structures. TZAZ INFOTECH prides itself on a team of
            architects and designers who push the boundaries of creativity.
            Their innovative designs redefine landscapes, creating spaces that
            inspire, evoke emotions, and serve functional purposes. From
            futuristic skyscrapers to eco-friendly residential complexes, TZAZ INFOTECH's portfolio is a testament to their design prowess.
          </p>
        </div>

        <div>
          <h2>Sustainability: A Cornerstone of TZAZ INFOTECH</h2>
          <p>
            In an era where environmental consciousness is paramount, TZAZ INFOTECH takes a definitive stance on sustainability. Each project
            is crafted with a deep respect for nature, integrating eco-friendly
            materials and energy-efficient systems. The company's commitment to
            sustainable practices not only preserves the environment but also
            results in structures that stand the test of time, ensuring a
            harmonious coexistence between human habitats and the natural world.
          </p>
        </div>

        <div>
          <h2>Client-Centric Philosophy: Beyond Construction</h2>
          <p>
          TZAZ INFOTECH's commitment to excellence goes beyond brick and
            mortar. Their client-centric philosophy places immense importance on
            understanding the unique needs of every client. By fostering
            transparent communication and collaboration, the company ensures
            that every project is a collaborative journey, where clients are
            active participants in the creation of their dreams. This approach
            not only leads to successful projects but also fosters long-lasting
            relationships based on trust and mutual respect.
          </p>
        </div>
        <div>
          <h2>Some Of Our Developed Product</h2>
          <h3 className="text-center mt-5 mb-3">Paragon Hotel Management</h3>
          <div className="row">
            <div className="col-12 col-lg-6 h-100 d-flex align-items-center justidy-content-center">
              <div className="d-flex align-items-center justidy-content-center w-100 h-100">
                <Swiper
                  spaceBetween={30}
                  hashNavigation={{
                    watchState: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="mySwiper"
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                >
                  <SwiperSlide data-hash="slide1">
                    <img
                      src={require("./Assets/Images/S1/WhatsApp Image 2023-10-14 at 18.03.28_6856b413.jpg")}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </SwiperSlide>
                  <SwiperSlide data-hash="slide2">
                    <img
                      src={require("./Assets/Images/S1/p2.jpg")}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="col-12 col-lg-6" style={{ maxHeight: "100%" }}>
              <div class="card">
                <div class="card-body">
                  <h3 className="mt-0" style={{}}>
                    Project Information
                  </h3>
                  <ul class="list-group list-group-flush">
                    <li class=" ">
                      <strong>Category: </strong> Windows Form Application
                    </li>
                    <li class=" ">
                      <strong>Client: </strong> Paragon Hotel and Resort
                    </li>
                    <li class=" ">
                      <strong>Project Year: </strong> 2023
                    </li>
                  </ul>
                  <h3 className="mt-0" style={{ marginTop: "0 !important" }}>
                    Project Details
                  </h3>
                  <p>
                    Paragon Hotel Management, a Windows Forms app, streamlines
                    hotel operations. It handles reservations, check-ins, room
                    assignments, billing, staff scheduling, and reporting.
                    User-friendly and secure, it enhances guest experiences,
                    aids hoteliers in business optimization, and maintains data
                    accuracy.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h3 className="text-center mt-5 mb-3">GO5050 Social Media</h3>
          <div className="row">
            <div
              className="col-12 col-lg-6 order-2 order-md-1 "
              style={{ maxHeight: "100%" }}
            >
              <div class="card">
                <div class="card-body">
                  <h3 className="mt-0" style={{}}>
                    Project Information
                  </h3>
                  <ul class="list-group list-group-flush">
                    <li class=" ">
                      <strong>Category: </strong> GO5050 Social Media
                    </li>
                    <li class=" ">
                      <strong>Client: </strong> Leo Kameni
                    </li>
                    <li class=" ">
                      <strong>Project Year: </strong> 2022
                    </li>
                  </ul>
                  <h3 className="mt-0" style={{ marginTop: "0 !important" }}>
                    Project Details
                  </h3>
                  <p>
                    GO5050 SOCIALSHARE NETWORK is a vibrant social media
                    application, built on a robust technological foundation. It
                    utilizes Blazor WebAssembly for responsive web performance,
                    implements Redis cache management for efficient data
                    handling, and employs the Onion architecture for structured
                    code organization. The application offers users an engaging
                    platform for social sharing and interaction, enhancing the
                    overall user experience. With Entity Framework, it ensures
                    efficient data management and retrieval, making it a
                    comprehensive and dynamic social network.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-md-2 ">
              <div className="d-flex align-items-center justidy-content-center w-100 h-100">
                <Swiper
                  spaceBetween={30}
                  hashNavigation={{
                    watchState: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="mySwiper"
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                >
                  <SwiperSlide data-hash="slide1">
                    <img
                      src={require("./Assets/Images/S1/s2.png")}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </SwiperSlide>
                  <SwiperSlide data-hash="slide2">
                    <img
                      src={require("./Assets/Images/S1/s3.png")}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2>Conclusion: Shaping Tomorrow, Today</h2>
          <p>
            In the realm of infrastructure and architecture development, TZAZ INFOTECH stands as a paragon of innovation, excellence, and
            sustainability. Their unwavering commitment to transforming visions
            into reality has not only shaped skylines but also transformed
            lives. As we move into a future where the demands on our
            infrastructure are greater than ever before, TZAZ INFOTECH
            continues to be the vanguard, pioneering solutions that redefine the
            way we live, work, and interact with our surroundings.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Test;
