import dotNet from "./DemodataAssets/dotNet.png"
import java from "./DemodataAssets/java.png"
import python from "./DemodataAssets/python.png"
import nodeJs from "./DemodataAssets/nodejs.png"
import front_end from "./DemodataAssets/front-end.png"
import react_native from "./DemodataAssets/react.png"
import java_script from "./DemodataAssets/js.png"
import android from "./DemodataAssets/android.png"
import ios from "./DemodataAssets/ios.png"


const developerDetails=[
    {
        name: ".Net Developer",
        image:dotNet
    },
    {
        name: "Java Developers",
        image:java
    },
    {
        name: "Python Developers",
        image:python
    },
    {
        name: "Node.js Developers",
        image:nodeJs
    },
    {
        name: "Front-End Developers",
        image:front_end
    },
    {
        name: "React Native Developers",
        image:react_native
    },
    {
        name: "JavaScript Developers",
        image:java_script
    },
    {
        name: "Android Developers",
        image:android
    },
    {
        name: "Ios Developers",
        image:ios
    }
]

export const getDevelopersDetails=()=>{
    return developerDetails
}