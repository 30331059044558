import React from 'react';
import "./style.css"
import img from "./Asset/codeSample.png"
const OurCodeSample = () => {
    return (
        <div className='centerContainer codeSampleContainer container'>
             <h1 className="tittleText">Our Architecture Sample</h1>
             <img src={img} alt='codeSampleImage'/>
        </div>
    );
};

export default OurCodeSample;