import React from 'react';
import "./style.css"
import image from "./Asset/architecher.png"
const OurArchitechcher = () => {
    return (
        <div className='centerContainer architecherContainer container'>
             <h1 className="tittleText">Our Architecture Sample</h1>
             <img src={image} alt='architecherImage'/>
        </div>
    );
};

export default OurArchitechcher;