import React from 'react';
import "./style.css"
import getEnsuringCodeQuality from './DemoData/EnsuringCodeDemoData';
const EnsuringQualityOfCode = () => {
    return (
        <div className='centerContainer container'>
            <h1 className="tittleText">Ensuring quality of coding</h1>
            <div className='condeContainer'>
                {
                    getEnsuringCodeQuality()?.map((item,idx)=>{
                        return(
                            <div key={idx} className='codeSingleCard'>
                                <h1>{item.name}</h1>
                                <ul>
                                    {
                                        item?.points?.map((item,idx)=><li key={idx}>{item}</li>)
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default EnsuringQualityOfCode;