import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//Css
import "./Css/App.css";
import "./Css/main.css";
import "./Css/responsive.css";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

//Pages
import Home from "./Pages/HomePage/Home";
import Career from "./Pages/CareerPage/Career";
import Project from "./Pages/ProjectPage/Project";
import AllProject from "./Pages/ProjectPage/AllProject";
import AllArticle from "./Pages/Article/AllArticle";
import ServiceDetails from "./Pages/ServicePage/ServiceDetails";
import Employee from "./Pages/EmployeePage/Employee";
import AllServices from "./Pages/AllServices/AllServices";
import ServiceDetailsView from "./Pages/AllServices/ServiceDetailsView/ServiceDetailsView";
import NavBar from "./Pages/HomePage/NavBar/NavBar";
import Footer from "./Pages/HomePage/Footer/Footer";
import Location from "./Pages/HomePage/Location/Location";
import Test from "./test";

function App() {
  return (
    <div>
      <React.StrictMode>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:slug" element={<ServiceDetailsView />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Career" element={<Career />} />
            <Route path="/project" element={<AllProject />} />
            {/* <Route path="/all-services" element={<AllServices />} /> */}
            <Route path="/our-services" element={<AllServices />} />
            <Route path="/project/:id" element={<Project />} />

            <Route path="/Article" element={<AllArticle />} />
            <Route path="/ServiceDetails/:id" element={<ServiceDetails />} />
            <Route path="/Employee" element={<Employee />} />
            <Route path="/Test" element={<Test />} />
          </Routes>
          <div id="location" style={{ padding: "10px" }}></div>
          <Location />
          <Footer />
        </BrowserRouter>
      </React.StrictMode>
    </div>
  );
}

export default App;
