import React, { useEffect, useState } from "react";
import axios from "axios";

function ArticleKeyword({ id }) {
  const [keywordData, setKeyword] = useState([]);
  useEffect(() => {
    axios
      .get(`https://testwebapiqttzaz.azurewebsites.net/api/Article/getArticleKeywordByID?id=${id}`)
      .then((response) => {
        setKeyword(response.data);
        console.log(response.data);
      });
  }, []);

  return (
    <div>
      {keywordData.map((data) => {
        return (
          <div className="KeywordContainer ps-md-3 pe-md-3" key={data.keywords}>
            {data.keywords}
          </div>
        );
      })}
    </div>
  );
}

export default ArticleKeyword;
